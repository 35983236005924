body.active-modal {
  overflow-y: hidden;
}

.button {
  background-color: #0071a4;
  width: 30px;
  height: 30px;
  border-radius: 40px;
  border: none;
  color: white;
  font-size: 30px;
  margin: 10px;
  display: grid;
  justify-content: center;
  align-items: center;
  font-family: var(--fontfamily);
}

.container-1 {
  display: flex;
  flex-direction: row;
  height: 89.5vh;
  width: 95vw;
  /* background-color: aqua; */
  justify-content: center;
}

.icon {
  padding-left: 5px;
  font-size: 30px;
  margin-left: 5px;
}

.overall {
  display: flex;
  width: 65vw;
  height: 95vh;
  flex-direction: column;
  /* background-color: #e06d0f; */
  margin-left: 2px;
}

.overallorder {
  display: flex;
  width: 30vw;
  height: auto;
  flex-direction: column;
  margin-right: 20px;
}

.billmatrial {
  background-color: #d9d9d980;
  align-items: center;
  width: auto;
  height: 50px;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  justify-content: space-between;
  padding: 5px;
  margin-right: 0.5px;
}

.billmatrialorder {
  background-color: #d9d9d980;
  justify-content: center;
  height: 50px;
  width: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 1px;
  padding: 5px;
  margin-left: 0.5px;
}

.View {
  height: 60vh;
  border-radius: 20px;
  overflow-y: scroll;
  background-color: rgb(240, 232, 232);
}

.calculater {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70px;
  margin-left: 20px;
}

.Product-name-amount {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 47px;
  margin-left: 20px;
  background-color: #7fafe9;
  border-radius: 10px;
  padding-left: 10px;
}

.Number {
  background-color: white;
  width: 40px;
  height: 40px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.Pbutton {
  width: 100px;
  height: 100px;
  margin: 4px;
  border-radius: 15px;
  overflow: hidden;
  font-family: var(--fontfamily);

}

.category-view {
  display: flex;
  width: 65vw;
  height: 80vh;
  margin-left: auto;
  overflow-y: auto;
}

.c-p-view {
  width: 100px;
  height: 100px;
  color: white;
  margin-top: 2px;
  border-radius: 20px;
  font-family: var(--fontfamily);
}

.Value {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.fonts {
  font-size: 20px;
  width: 200px;
  /* background-color: #e06d0f; */
}
.fonts_value {
  font-size: 20px;
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color : #D70015;
  /* background-color: #27e00f; */
}

.checkout {
  width: 190px;
  height: 60px;
  background-color: var(--background-color);
  font-size: x-large;
  font-weight: bolder;
  color: aliceblue;
  border-radius: 30px;
  cursor: pointer;
  font-family: var(--fontfamily);
}

.neworder {
  width: 190px;
  height: 60px;
  background-color: #e06d0f;
  font-size: x-large;
  font-weight: bolder;
  color: aliceblue;
  border-radius: 30px;
  cursor: pointer;
  font-family: var(--fontfamily);
}

.bom-overview {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px;
}

.Bom {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 28px;
  width: auto;
  margin-top: 5px;
  margin-right: 20px;
  justify-content: space-between;
}

.modal,
.overlay {
  width: auto;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: auto;
  min-width: 300px;
  max-height: 630px;
}

.modal-content-email {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  border: 1px solid black;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: auto;
  min-width: 300px;
  max-height: 600px;
}

.addbtn {
  background-color: #0071a4;
  height: 40px;
  color: #f1f1f1;
  border-radius: 10px;
  font-size: x-large;
  font-family: var(--fontfamily);
}

.Homepage-Category {
  width: 95vw;
  height: 90vh;
  overflow: hidden;
  padding-left: 10px;
}

.Homepage-Category_2 {
  width: 94.5vw;
  height: 90vh;
  display: flex;
  overflow: auto;
}

@media (max-width: 768px) {
  .Homepage-Category, .Homepage-Category_2 {
    width: 100vw;
    height: 80vh;
    padding-left: 5px; 
  }
}

@media (max-width: 480px) {
  .Homepage-Category, .Homepage-Category_2 {
    width: 100vw;
    height: auto;
    padding-left: 5px;
    flex-direction: column;
  }
}

.Category-title {
  width: 135px;
  background-color: #d9d9d980;
  border-radius: 20px;
  padding: 3px;
  display: grid;
  margin-right: 40px;
}

.Order-title {
  width: 450px;
  border-radius: 20px;
  background-color: #d9d9d980;
  padding: 3px;
  display: grid;
  align-items: center;
}

.body-home {
  display: flex;
  width: 1400px;
  height: 610px;
  margin-top: 10px;
  margin-bottom: 10px;
  /* background-color: #7fafe9; */
}

.body-Category {
  width: 900px;
  height: 550px;
  margin-top: 10px;
  display: flex;
  overflow-y: auto;
  /* background-color: #035bc9; */
}

.body-Order {
  width: 460px;
  height: 615px;
  display: grid;
  overflow: hidden;
  /* background-color: #e06d0f; */
}

.Category-name {
  display: grid;
  justify-content: space-around;
  align-items: center;
  width: 135px;
  height: fit-content;
  border-radius: 20px;
  background-color: #d9d9d980;
}

.body-Product {
  height: fit-content;
  width: 750px;
  overflow-y: auto;
}

.order-details {
  height: 350px;
  overflow-y: scroll;
  width: 440px;
  background-color: rgb(240, 232, 232);
  border-top-left-radius: 20px;
}

.order-calculater {
  width: 450px;
  height: 150px;
}

.calculate-view-options {
  width: 450px;
  height: 700px;
  margin-top: 10px;
  margin-left: 10px;
}

.Welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 700px;
  height: 500px;
}
.discount_type{
  width: 80px;
  height: 40px;
  font-size: large;
  background: none;
  border-radius: 10px;
  padding: 5px;
}
.discount_typee{
  width: 80px;
  height: 35px;
  border-radius: 15px;
  font-size: large;
  padding: 5px;
}

.content-laundry{
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
}

.food-grid-laundry{
  display: grid;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  max-height: 500px;
  padding: 20px;
  width: 100%;
  height: 0%;
  border: 1px solid #ccc;
  border: none;
  height: auto;
}

.food-items-laundary {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 160px; 
  height: auto;
  max-height: 170px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 5px;
  background-color: #f7fafa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

/* .food-items-laundary:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
} */

.food-items-laundary img {
  border-radius: 20px 20px 0 0;
  width: 90%;
  height: 60%;
  object-fit: contain;
  margin: 0px 0px 0px 0px;
}

.food-items ul {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.food-items li {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust spacing between input and button */
}

.search-input-laundry {
  flex-grow: 1; /* Allows the input to take up available space */
}

.search-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.search-button:hover {
  background-color: #0056b3;
}

.search_home-laundry {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa; 
  border-radius: 8px;
  width: 100%;
  max-width: 700px; 
  margin: 0 auto;
}

.search-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-input-laundry {
  width: 400px;
  height: 48px;
  background-color: #f9fbfc;
  border-radius: 25px;
}

.search-input-laundry .MuiOutlinedInput-root {
  border-radius: 25px;
}

.search-input-laundry .MuiOutlinedInput-root fieldset {
  border-color: #cfd8dc; 
}

.search-input-laundry .MuiOutlinedInput-root:hover fieldset {
  border-color: #90caf9; 
}

.search-input-laundry .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #42a5f5;
}

.search-input-laundry .MuiInputLabel-root {
  color: #90a4ae; 
}

.search-input-laundry .MuiInputLabel-root.Mui-focused {
  color: #42a5f5; 
}

.search-input-laundry.MuiOutlinedInput-input {
  padding: 12px 16px; 
}

.search_home-laundry{
  display: flex;
  justify-content: end;
  margin-top: 9px;
  height: 60px;
}

.search_home ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  margin: 0;
  padding: 0;
  width: 35%;
  margin-right: 80px;
  list-style-type: none;
  /* background-color: #f5f5f5; */
  border-radius: 8px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  transition: all 0.3s ease;
}

.search_home ul li {
  padding: 10px 20px;
  cursor: pointer;
}

.search_home ul li:hover {
  /* background-color: #e0e0e0; */
  transform: scale(1.05);
}


