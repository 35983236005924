.order_head_over {
  width: 100%;
  height: auto;
}

.first-order {
  width: 74%;
  height: auto;
}

.over-order {
  display: flex;
}

.res-order-detail {
  width: 26%;
  height: auto;
}

.order-dropdown {
  height: 80px;
}

.res-head-drop {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background-color: #f1f1f1;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-height: 200px;
}

.order-detail-res {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  height: 620px;
  overflow: auto;
}

.order-detail-res h2 {
  font-size: 1.5rem;
  color: #333;
  padding: 10px 0;
  margin-bottom: 15px;
  border-bottom: 2px solid #e0e0e0;
}

.order_detail {
  background-color: #fff;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.order-detail ul {
  list-style: none; 
  padding: 0;
  margin: 0;
}

.order-detail li {
  font-size: 1rem;
  color: #555;
  margin: 5px 0;
}

.order-detail span {
  font-weight: bold;
  color: #333;
  margin-left: 10px;
}

.order-detail ul>div {
  margin-top: 10px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.order-detail ul>div li {
  flex: 1;
}

.order-detail ul>div span {
  flex: 2;
  font-size: 0.9rem;
  color: #777;
}

.res-order-detail button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.res-order-detail button:hover {
  background-color: #0056b3;
}

.date-filter-select {
  margin-right: 16px;
  height: 40px;
  width: 200px;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 8px;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.date-filter-select:hover {
  border-color: #888;
}

.date-filter-select:focus {
  border-color: #0056b3;
  box-shadow: 0 0 4px rgba(0, 86, 179, 0.5);
}

.Customizedate-filter-select {
  margin-right: 16px;
  height: 40px;
  width: 150px;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 8px;
  outline: none;
  font-size: 16px;
  cursor: pointer;
}

.filter-continer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 20px -10px 20px;
}

.Res-order-search {
  margin-right: 16px;
  width: 300px;
  background-color: white;
}

.import-container,
.export-container {
  margin-bottom: 1rem;
}

.import-button-wrapper {
  display: inline-block;
  position: relative;
  margin-right: 1rem;
}

.import-button {
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.import-button:hover {
  background-color: #0056b3;
}

.hidden-input {
  display: none;
}

.export-label {
  font-size: 1rem;
  margin-right: 0.5rem;
  
  color: #333;
}

.export-select {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  margin-top: 10px;
  transition: border-color 0.3s ease;
}

.export-select:hover {
  border-color: #007bff;
}

.export-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

.order-details-container {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.order-header {
  font-size: 2rem;
  color: #2c3e50;
  text-align: center;
  /* margin-bottom: 25px; */
  padding-bottom: 4px;
  border-bottom: 2px solid #e0e0e0;
  font-weight: 600;
}

.order-content {
  background-color: #f9fafb;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.order-info-section {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.info-row {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: #555;
}

.info-row strong {
  color: #007bff;
  font-weight: 600;
}

.info-row span {
  font-weight: 500;
  color: #333;
}

.product-table {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ddd;
  overflow: hidden;
  overflow-y: scroll;
  height: 320px;
}

.product-header {
  display: flex;
  justify-content: space-between;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  padding: 10px;
}

.product-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.product-row:nth-child(even) {
  background-color: #f8f9fa;
}

.product-column {
  flex: 1;
  text-align: center;
  font-size: 1rem;
}

.grand-total {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 2px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  font-weight: bold;
  color: #28a745;
}

/* Button Styling */
.action-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 30px;
}

.pay-button,
.print-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  min-width: 150px;
  text-align: center;
}

.pay-button {
  background-color: #28a745;
}

.pay-button:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

.print-button {
  background-color: #dc3545;
}

.print-button:hover {
  background-color: #c82333;
  transform: translateY(-2px);
}

.no-order-message {
  text-align: center;
  font-size: 1.2rem;
  color: #999;
  padding: 20px;
  font-style: italic;
}
.print-button-order{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  background-color: #007bff;
  min-width: 150px;
  text-align: center;
}
.total-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.total-label {
  font-weight: 800;
  color: #444;
}

.total-value {
  font-weight: 700;
  color: #222;
}

@media print {
  body * {
    visibility: hidden;
  }
  .print-only, .print-only * {
    visibility: visible;
  }
  .print-only {
    position: absolute;
    /* left: 0; */
    top: 0;
    width: 100%;
  }
}

.Print_popup_restarunt_order{
  position: fixed;
  top: 0%;
  left: 0%;
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  height: auto;
  border-radius: 3px;
  background-color: aliceblue;
  font-size: 14px;
  color: black;
}
