.Edit-overall {
  width: 95vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Header-edit {
  width: 92vw;
  height: 5vh;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #d9d9d980;
  padding-left: 10px;
}

li {
  list-style: none;
}

.edit-menu {
  width: 92vw;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #e1e1e1;
  list-style-type: none;
  margin-top: 2px;
  left: 5%;
}

.btn-edit {
  font-size: x-large;
  background: none;
  border: none;
  margin: 0px 30px 0px 30px;
  font-family: var(--ffamily);
  color: var(--color);
}

.selected {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  background-color: azure;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 17px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.category-edit {
  display: flex;
  width: 95vw;
  margin-left: 40px;
}

.category-add-product {
  display: flex;
  flex-direction: row-reverse;
  /* justify-content: space-around; */
  width: 89vw;
  /* justify-content: flex-end; */
  margin-top: 15px;
  margin-bottom: 15px;
  /* margin-right: 140px; */
}
.category-add-product .MuiOutlinedInput-input::-webkit-input-placeholder {
  font-size: 20px;
  color: black;
}
.category-add-product .MuiOutlinedInput-input::-moz-placeholder {
  font-size: 20px;
  color: black;
}

.category-add .MuiOutlinedInput-input:-ms-input-placeholder {
  font-size: 20px;
  color: black;
}

.category-add .MuiOutlinedInput-input::placeholder {
  font-size: 20px;
  color: black;
}

.delete_user_setting {
  width: 100%;
}
.delete_user_setting h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.conformation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-left: 40px;
}
.username_identity {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user_name {
  font-weight: bold;
}
.btnconfirm-edit_user {
  margin-top: 18px;
  display: flex;
  justify-content: space-evenly;
  margin-left: 10px;
  width: 100%;
}

.icon-btn-edit {
  background: none;
  border: none;
  margin-top: 6px;
  margin-right: 7px;
  cursor: pointer;
}

.add-btn-edit {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  width: 200px;
  border-radius: 20px;
  margin-bottom: 10px;
  background-color: #518edf80;
}

.modals,
.overlays {
  width: auto;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow: scroll;
}

/* .scroll-button{
  width: 800px;
  height: 600px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow: scroll;
  background-color: green;
} */

.overlays {
  background: rgba(49, 49, 49, 0.8);
}

.modal-contents {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

@media screen and (max-width: 125%) {
  .modal-contents {
    top: 10vh;
  }
}

@media screen and (min-width: 125%) {
  .modal-contents {
    top: 0;
    transform: translate(-50%, 0);
  }
}

.modal-contents-product {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 550px;
  height: auto;
}

.edit-popupscreen {
  width: 2000px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Deleted_popuupscroll {
  width: 2000px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -10px;
}

.addcategory-edit {
  display: flex;
  flex-direction: column;
  width: 550px;
  height: 280px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.addcategory-editadd {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 200px;
  justify-content: center;
  align-items: center;
}
.addcategory-edit-product {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 200px;
  justify-content: center;
  align-items: center;
}
.cancelbtn-edit {
  color: rgb(158, 64, 64);
}

.edit-menu li {
  display: inline-block;
  margin-right: 10px;
}

.btn-edit {
  display: block;
  padding: 2px 10px;
  text-decoration: none;
  transition: transform 0.3s;
}

.btn-edit:hover {
  transform: scale(1.1);
}

.removebtn-edit {
  display: flex;
  justify-content: end;
  width: 32vw;
}

.btnconfirm-edit {
  display: flex;
  width: 400px;
  justify-content: space-evenly;
  margin-top: 20px;
}

.modal-contenters {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 4px;
  max-width: 600px;
  min-width: 300px;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-popupscreens {
  width: 1500px;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
}



/* Container for form and title */
.addcategory-edit-p {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

/* Title styling */
.addcategory-edit-p h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

/* Form container styling */
.edit_productsty {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  padding: 15px;
  box-sizing: border-box;
}

/* Input field styling */
.MuiTextField-root {
  margin-top: 15px;
}

.MuiTextField-root .MuiInputLabel-root {
  font-size: 16px;
  color: #333;
}

.MuiTextField-root input {
  font-size: 14px;
  padding: 12px;
}

/* Error styling for fields */
.Mui-error .MuiInputLabel-root,
.Mui-error .MuiOutlinedInput-root {
  color: #f04141;
  border-color: #f04141;
}

/* Category select styling */
.FormControl-root {
  margin-top: 20px;
  width: 100%;
}

/* Button and icon container */
.confirm_btn_edit {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
  margin-top: 20px;
  align-items: center;
}

.confirm_btn_edit > div {
  margin: 0 10px;
}

/* Upload image button container */
.edit-product-popupscreens div img {
  max-width: 50px;
  height: 50px;
  object-fit: cover;
  margin-top: 10px;
}

/* Close icon button style */
.cancel-icon-AddProduct {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Responsive styling */
@media (max-width: 768px) {
  .edit-product-popupscreens {
    width: 100%;
    padding: 10px;
  }

  .edit_productsty {
    width: 100%;
    padding: 10px;
  }

  .confirm_btn_edit {
    flex-direction: column;
    align-items: center;
  }

  .confirm_btn_edit > div {
    margin: 10px 0;
  }
}


.onchange-click {
  background-color: white;
  margin-top: 18px;
  width: 150px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.edit_productsty {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.confirm_btn_edit {
  display: flex;
  justify-content: space-between;
  width: 450px;
  margin-left: 40px;
}

.user_input {
  display: grid;
  grid-template-columns: auto auto;
  width: 540px;
  align-items: center;
}

.Edit_page_Name {
  width: 600px;
  display: flex;
  justify-content: center;
}

.Inputuser {
  width: 250px;
  height: 70px;
  margin: 20px;
}

.Customer_data {
  display: flex;
  flex-direction: column;
  width: 95vw;
  height: 80vh;
  /* justify-content: center; */
  align-items: center;
  overflow-x: hidden;
}

.Searchbox {
  display: flex;
  width: 300px;
  height: auto;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 20px;
}

.customer_Table {
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: start;
  height: auto;
  overflow: auto;
}

.open-poppuscreen {
  display: grid;
  grid-template-columns: auto auto;
  width: 600px;
  justify-content: space-evenly;
  align-items: center;
}

.selected_Edit {
  background-color: white;
  font-size: x-large;
  padding-left: 20px;
  padding-right: 20px;
  margin: 20px 0px 0px 20px;
  border-radius: 10px;
  color: var(--background-color);
  font-family: var(--ffamily);
}

.Edit-home {
  display: flex;
  margin-top: 6%;
  margin-left: -25%;
}

.conform-btn-location {
  display: flex;
  width: 500px;
  justify-content: space-evenly;
  margin-top: 30px;
}

.container_Location {
  display: flex;
  width: 400px;
  height: 250px;
  border: 3px solid rgb(52, 51, 51);
  padding: 10px;
  margin-top: 5px;
  flex-direction: row;
  position: relative;
}

.container_Location:hover {
  background-color: rgba(233, 233, 230, 0.961);
  transform: scale(1.025);
  min-height: 250px;
  height: fit-content;
  max-height: 300px;
}

.dropdown-contents {
  position: absolute;
  right: 0;
  bottom: 156px;
  background-color: white;
  border-radius: 10px;
  width: 70px;
  margin-right: 5px;
  z-index: 2;
}

.dropdown-contents .edit,
.dropdown-contents .delete {
  display: flex;
  padding: 10px;
  height: 30px;
  cursor: pointer;
  align-items: center;
}

.dropdown-contents .edit:hover,
.dropdown-contents .delete:hover {
  display: flex;
  font-weight: bold;
  background-color: #f0f0f0;
  align-items: center;
  width: 69px;
  border-radius: 7px;
}

.Branch_display {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 30px;
  row-gap: 30px;
  padding: 10px;
  font-display: bold;
  flex-grow: 1;
}

.Location_edit_btn {
  display: flex;
  height: 50px;
  width: 20px;
  background-color: rgb(76, 0, 255);
  position: relative;
  bottom: 80px;
  left: 40px;
}

.rolebutton {
  display: flex;
  margin-top: 30px;
}

.rolecancel {
  margin-right: 5px;
}

.roleconfirm {
  margin-left: 5px;
}

.edit-Branch_popupscreen {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Address-textbox {
  display: grid;
  border: 2px solid rgba(187, 186, 186, 0.755);
  width: 510px;
  height: 90px;
  margin-left: 15px;
  margin-bottom: 2px;
  border-radius: 4px;
  padding: 8px;
  overflow: scroll;
}

.Branch-notes {
  width: 560px;
  margin-top: 10px;
  background-color: #8a8e8b;
  color: black;
  padding: 2px;
  margin-left: -10px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 10px;
}

.input-typeaddress {
  margin-top: 90px;
  position: relative;
  border: 0.5px solid black;
  left: 40px;
  bottom: 30px;
  width: 515px;
  display: grid;
  flex-direction: column;
  border-radius: 5px;
}

.Input-Email {
  position: relative;
  left: 40px;
  top: 37px;
  width: 515px;
}

.address-optional {
  display: flex;
  padding: 10px;
  flex-direction: column;
}

.inputphone {
  width: 250px;
  position: relative;
  top: 20px;
  right: 10px;
}

.Inputusername {
  width: 250px;
  position: relative;
  left: 8px;
  top: 20px;
}

/* .text-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  width: 100%;
  display: block;
  overflow: auto;
  height: auto;
  max-height: fit-content;
  width: 45vh;
  background-color: aqua;
} */
.text-wrap {
  display: flex;
  flex-direction: column;
}

.text-wrap ul {
  display: flex;
  flex-direction: column;
}

.text-wrap ul li {
  display: flex;
}

.text-wrap p {
  width: 200px;
}

.text-wrap h4 {
  width: 200px;
}

.text-wrap ol h4 {
  margin-top: 5px;
}

.text-wrap dl {
  margin-left: 40px;
  width: 350px;
  height: auto;
}

.cancel-icon-Branch {
  position: relative;
  left: 540px;
  bottom: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.cancel-icon-user {
  position: relative;
  left: 285px;
  bottom: 42px;
  background: none;
  border: none;
  cursor: pointer;
}

.cancel-icon-Addcategory {
  position: relative;
  left: 210px;
  bottom: 5px;
  background: none;
  border: none;
  cursor: pointer;
}

.cancel-icon-AddProduct {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  transform: translateY(-50%);
  margin-left: 550px;
}

.cancel-icon-branchdelete {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  margin-left: 376px;
}

.cancel-icon-userdelete {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  margin-left: 540px; 
}

.cancel-icon-cat-delete {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  margin-left: 515px; 
  margin-top: -10px;
}

.cancel-icon-productdelete {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  margin-left: 560px; 
  margin-top: -10px;
}

.cancel-icon-Editcustomer {
  position: relative;
  left: 178px;
  bottom: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.cancel-icon-EditProduct {
  position: relative;
  left: 185px;
  bottom: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.cancel-icon-Editcategory {
  position: relative;
  left: 208px;
  bottom: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.cancel-icon-EditBranch {
  position: relative;
  left: 210px;
  bottom: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.cancel-icon-SelectBranch {
  position: relative;
  left: 182px;
  bottom: 18px;
  background: none;
  border: none;
  cursor: pointer;
}

.cancel-icon-Edituser {
  background: none;
  border: none;
  position: relative;
  left: 545px;
  bottom: 45px;
}

.Edit_header {
  display: flex;
  justify-content: center;
}

.btn_group {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.Role_option {
  margin-top: 15px;
}

.Role_option_edit {
  display: grid;
}

.Role_select {
  display: flex;
}

.Mahi {
  display: flex;
}

.deleted_items {
  background-color: rgb(99, 148, 239);
  position: relative;
  right: 20px;
  width: 150px;
  height: 40px;
  font-size: large;
  border-radius: 20px;
  cursor: pointer;
}

.Cancel_icon_delete {
  background: none;
  border: none;
  position: relative;
  left: 600px;
  bottom: 20px;
}

.modals,
.overlays {
  width: auto;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow: scroll;
}

.modal-confirm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

.edit_userpop {
  width: 2000px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -10px;
}

.search_box {
  display: flex;
  flex-direction: row-reverse;
  width: 22vw;
  margin-bottom: 20px;
  margin-right: 30px;
}

.Deleted_check {
  position: relative;
}

.edit_textfield {
  position: relative;
  left: 45px;
  bottom: 20px;
}

.customer-form {
  display: flex;
  flex-direction: column; 
  gap: 10px;
  /* padding: 10px; */
  width:500px; 
}

.input-field {
  width: 100%; 
  background-color: white;
}

.required {
  color: #f04141;
  font-size: 14px;
}


@media (min-width: 640px) {
  .customer-form {
    grid-template-columns: 1fr 1fr;
  }
}

.button-data{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 15px;
}

.customer-Aaddress{
  display: flex;
  flex-direction: column;
}

.customer-input-address ul {
  border: solid 1px #a09d9dd3;
  padding: 8px;
  display: grid;
  border-radius: 5px;
  width: 100%;
  /* margin-left: 10px; */
}

.customer-heading{
  display: flex;
  justify-content: center;
}

.over-head-style {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
}

.customer-close {
  display: flex;
  align-items: center; 
  padding: 5px; 
  cursor: pointer;
}

.header-product-structure {
  display: flex;
  align-items: center; 
  justify-content: space-between;
  width: 88vw;
  height: 60px;
  padding: 10px 20px;
  background-color: #f8f9fa; 
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  gap: 15px;
}

.header-product-structure > div {
  display: flex;
  align-items: center;
  gap: 15px;
}

.category-filter {
  display: flex;
  align-items: center;
}

.category-filter .MuiFormControl-root {
  min-width: 280px;
}

.Deleted_check {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.search_box_user {
  flex: 1;
}

.search_box_user .MuiTextField-root {
  width: 100%;
  max-width: 350px;
}

.add-product-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #8bc34a; 
  color: white;
  padding: 8px 15px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.add-product-btn:hover {
  background-color: #689f38;
}

.category-add {
  display: flex;
  flex-direction: row-reverse;
  width: 89vw;
  margin-top: 25px;
  margin-bottom: 15px;
}

.header-product-structure-catogory {
  display: flex;
  flex-direction: row-reverse; 
  align-items: center; 
  justify-content: flex-end; 
  width: 88vw;
  height: 60px;
  padding: 10px 20px;
  background-color: #f8f9fa; 
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  gap: 15px;
}

.search_box_user {
  flex: 1;
}

.Deleted_check {
  display: flex;
  align-items: center;
}

#Addcategory {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customer-add {
  display: flex;
  flex-direction: row-reverse; 
  width: 89vw;
  margin-top: 25px;
  margin-bottom: 15px;
}

.header-product-structure-category-customer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 88vw;
  height: 60px;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  gap: 15px; 
}

.search-box {
  display: flex;
  align-items: center;
}

.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.branch-add{
  display: flex;
  flex-direction: row-reverse; 
  width: 89vw;
  margin-top: 25px;
  margin-bottom: 15px;
}

.header-product-structure-category-branch{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 88vw;
  height: 60px;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  gap: 15px; 
}

.user-add {
  display: flex;
  width: 89vw;
  margin-top: 25px;
  margin-bottom: 15px;
}

.header-product-structure-user {
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: flex-end;
  width: 88vw;
  height: 60px;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  gap: 15px; 
}

.search_box_user {
  display: flex;
  align-items: center;
}

.Deleted_check {
  display: flex;
  align-items: center;
}

#Addcategory {
  display: flex;
  align-items: center;
}