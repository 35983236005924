.Order-View {
  display: flex;
  width: 1100px;
  height: 900px;
  justify-content: space-between;
}
.sub-view {
  width: 320px;
  background-color: lightblue;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.order-icons {
  display: flex;
  background-color: rgb(66, 77, 72);
  color: white;
  width: 300px;
  height: 110px;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 5px;
}
.overflow-page {
  overflow-y: auto;
}
.modal,
.overlay {
  width: auto;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.modal-content {
  position: absolute;
  transform: translate(-50%, -40%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
}
.over-y-flow {
  overflow-y: auto;
  height: 600px;
}
