.MuiTableCell-head {
  background-color: rgb(126, 118, 118);
  color: white;
  text-align: center;
}

.btnconfirm {
  width: 850px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.modal,
.overlay {
  width: auto;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.modal-content {
  position: absolute;
  transform: translate(-50%, -40%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 20px;
}

.over-y-flow {
  overflow-y: auto;
}

.paymentviews {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.paymentviews1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  margin-left: 10px;
}

.payviews {
  display: flex;
  width: 400px;
  height: 300px;
  justify-content: space-between;
}

.paymentmodes {
  width: 180px;
  height: 180px;
}

.psview1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 600px;
}

.Popups {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.payments {
  margin-top: -20px;
}

.i-cons {
  height: 58px;
  width: 58px;
}

.pay-view {
  display: flex;
  justify-content: center;
  margin-top: -10px;
}

.btn-s {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.Overall_Order {
  width: 95vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  overflow: auto;
  position: relative;
  
}

.Order {
  margin-bottom: 20px;
}

.TableContainer {
  display: flex;
  justify-content: center;
}

.Ordername {
  height: 40px;
  width: 95vw;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #d9d9d980;
  padding-left: 20px;
}

.overorder {
  display: flex;
  width: 100%;
  height: 1170px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
}

.inventoryicon {
  color: black;
}

.import_Export {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 80vw;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 10px;
}

.import_Export select {
  padding: 2px;
  height: 35px;
  border-radius: 50px;
  font-size: medium;
  border: 2px solid var(--color);
  pointer-events: painted;
}

.import_Export select option :hover {
  background-color: var(--color);
}

.Im_Expo {
  margin-left: 5px;
}

.order_stage {
  display: flex;
  margin: 10px;
  width: 95vw;
  max-width: 1350px;
  min-width: 625px;

}

.overall_page {
  width: 70vw;
  max-width: 1000px;
  min-width: 550px;
  max-height: 90vh; 
  overflow-y: auto; 
  overflow-x: hidden;
}

Checkbox {
  cursor: no-drop;
}

@media(max-width:430px) {
  .customer_page {
    width: 25vw;
    max-width: 430px;
    min-height: 275px;
    /* background-color: red; */
  }
}

.Produt_name {
  display: flex;
}

.product_details {
  display: flex;
  height: 70px;
  background-color: antiquewhite;
  justify-content: space-between;
  margin: 10px;
  border-radius: 3px;
  align-items: center;
  padding: 8px;
}

.product_details li {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 170px;
}

.price_detailss {
  height: auto;
  border: 2px dotted black;
  border-radius: 5px;
}

.price_detailss ul {
  display: grid;
  justify-content: space-between;
  /* padding: 7px; */
  margin-top: 3px;
}

.price_detailss li {
  /* margin: 7px; */
  padding: 4.5px;
  display: flex;
  justify-content: space-between;
}

.price_detailss h3 {
  width: 140px;
  font-size: medium;
}

.price_detailss p {
  display: flex;
  width: 140px;
  flex-direction: row-reverse;
  font-weight: bold;
}

.cancel {
  margin-top: 10px;
  margin-left: 50px;
  width: 130px;
  height: 40px;
  border-radius: 30px;
  color: rgb(75, 125, 233);
  font-weight: bold;
  border-color: rgb(135, 135, 240);
  font-weight: 20px;
  font-size: 19px;
  background-color: white;
}

.dropp_dowwnn {
  background-color: red;
}

.print_receipt {
  background-color: #719df3;
  width: 140px;
  height: 40px;
  border-radius: 20px;
  font-size: medium;
  margin-top: 10px;
  color:white;
  font-weight: bold;
  border:none;
}

.dropp_dow {
  width: 100%;
  position: relative;
  left: -9px;
}

.Payconfirm_Icon {
  background-color: var(--colors);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-top: -75px;
}

.icons_pay {
  color: var(--colors);
  background-color: rgb(255, 255, 255);
  border-radius: 40px;

}

.confirmhead {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  width: 350px;
  height: 150px;
  border-radius: 10px 10px 20px 20px;
  border: 1px dotted black;
}

.payment_popup {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  width: 500px;
  height: 600px;
}

.conform {
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 15px;
  max-width: auto;
  min-width: 300px;
  max-height: 600px;
}

.conform-done {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 15px;
  width: 600px;
  max-height: 500px;
}
.order_delivery {
  font-size: larger;
  margin-top: 20px;
  margin-left: 65px;
  color: rgb(79, 223, 79);
}

.inside_page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  /* border-radius: 20px; */
  width: 300px;
  margin-left: -20px;
  /* border: 2px solid black; */
  margin-left: 3px;
}

.payment_type {
  background-color: rgb(235, 232, 232);

}

.payment_Methodss {
  height: 100px;
  border: 2px dotted black;
  border-radius: 3px;
  padding: 7px;
  padding-left: 10px;
}

.payment_Methodss ul {
  display: flex;
  font-size: larger;
  padding: 7px;
  justify-content: space-between;
  width: 300px;
}

.payment_modee {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  height: 45px;
  width: 240px;
  padding-top: 3px;
  padding-left: 10px;
  border: 1px solid black;
  border-radius: 7px;
  background-color: rgb(211, 216, 220);
  align-items: center;
}

.order_pages {
  margin-left: -20px;
  width: 330px;
  padding-left: 10px;
  margin-top: 20px;
}

.order_pages li {
  margin: 10px;
  display: flex;
  justify-content: space-between;
}

.order_pages p {
  font-size: medium;
  width: 150px;

}

.changes_details_buy {
  display: flex;
  margin-bottom: 55px;
  width: 320px;
  border: 2px solid black;
  border-radius: 15px;
  margin-right: 10px;
  margin-top: 2px;
  padding: 10px;
  font-size: 30px;
}

.changes_details_buy li {
  display: flex;
  justify-content: space-between;
  padding: 3px;
  font-weight: normal;
}

.changes_details_buy h5 {
  font-size: medium;
  width: 170px;
  font-weight: normal;
}

.changes_details_buy p {
  display: flex;
  font-size: medium;
  flex-direction: row-reverse;
  width: 120px;
  font-weight: normal;
}

.changes_details {
  display: flex;
  margin-bottom: 55px;
  width: 450px;
  background-color: yellow;
  /* border-radius: 15px;
  margin-right: 10px;
  margin-top: 2px;
  padding: 10px;
  font-size: 30px; */
}

.changes_details li {
  display: flex;
  max-Width: 250px;
  min-Width: 190px;
  flex-Direction: column;
  justify-Content: space-around;
  /* background-color: red; */
}

.changes_details h5 {
  /* font-size: medium;
  width: 200px; */
  display: flex;
  font-size: medium;
  width: 250px;
  flex-Direction: column;
  margin-left: 30px;
  background-color: aqua;
}

.changes_details p {
  display: flex;
  font-size: medium;
  flex-direction: row-reverse;
  width: 200px;
  margin-left: 250px;
  background-color: red;
}

.changes_details input {
  width: 80px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid black;
  margin-left: 4px;
  padding-left: 5px;
}

.btn_con {
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 400px;
}

.payment_btn {
  margin-left: 50px;
}

.Confirm_Print_mail {
  display: flex;
  justify-content: center;
  width: 600px;
  margin-top: 10px;
  margin-left: 90px;
}

.buttonon {
  margin: 3px;
  width: 300px;
  height: 40px;
  background-color: var(--colors);
  color: #f1f1f1;
  border-radius: 10px;
  padding: 2px;
}

.payment_mode_cash {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  height: 45px;
  width: 200px;
  padding-top: 2px;
  padding-left: 22px;
  border: 1px solid black;
  border-radius: 7px;
  background-color: rgb(211, 216, 220);
  align-items: center;
}

.order_pages_cash {
  display: flex;
  justify-content: center;
  width: 380px;
  height: 125px;
  padding-left: 10px;
  margin-top: 30px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.order_pages_cash li {
  margin: 10px;
  display: flex;
  justify-content: space-between;
}

.order_pages_cash p {
  font-size: medium;
  width: 150px;
}

.btn_con_cash {
  margin-left: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 400px;
}

.order_pages {
  margin-left: -20px;
  width: 330px;
  padding-left: 10px;
  margin-top: 20px;
}

.order_pages li {
  margin: 10px;
  display: flex;
  justify-content: space-between;
}

.order_pages p {
  font-size: medium;
  width: 150px;

}

.order_pages_message {
  margin-left: -20px;
  width: 330px;
  padding-left: -20px;
  margin-bottom: 100%;
  background-color: aqua;
}

.order_pages_message h5 {
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  background-color: blue;
}

.order_pages_message p {
  font-size: medium;
  width: 150px;
  justify-content: space-between;
  margin-left: 120px;
  background-color: red;
}

.button_tags {
  display: flex;
  justify-content: space-between;
}

.input_date {
  width: 120px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid black;
  margin-right: 10px;
  padding-left: 5px;
}

.Select_Date_cus {
  justify-content: center;
  display: flex;
  width: 500px;
  margin: 10px;
}

.Select_Date_cus button {
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.input_section {
  position: relative;
  width: 7rem;
  height: 2rem;
  font-size: large;
}

.option {
  position: relative;
  font-size: large;
}

.lable_export {
  font-size: large;
  position: relative;
  top: 5px;
  white-space: nowrap;
  display: inline-block;
}

.select_expo {
  width: 8rem;
  height: 30rem;
  cursor: pointer;
  position: relative;
  top: 5px;
}
@media (max-width :1401){
  .selection_option {
    display: flex;
    position: relative;
    margin-top: 10px;
    display: flex;
    /* width: 30rem; */
    height: 40px;
    cursor: pointer;
    /* background-color: red; */
  }
}

@media(max-width: 1401) {
  .lable_export {
    font-size: large;
    position: relative;
    top: 0;
    display: inline-block;
  }
}

.popup-cash {
  width: 450px;
  height: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.popup-cash-done{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 450px;
  height: 420px;
}
.popup-bill-cash {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-top: -300px; */
  margin-bottom: 290px ;
}

.psinput-cash {
  width: 80px;
  height: 40px;
  font-size: large;
  background: none;
  border-radius: 10px;
}

.print-button {
  background-color: rgb(238, 231, 231);
  font-size: large;
  bottom: 80px;
  border-radius: 30px;
  height: 40px;
  width: 140px;
  margin-top: 30px;
  cursor: pointer;
}

.atom-order {
  background: none;
  border: none;
}

.back-Arrow2-order {
  width: 50px;
  margin-right: 370px;
  position: relative;
  top: 40px;
}

.cancel-icon-order {
  position: relative;
  left: 360px;
  background: none;
  border: none;
  cursor: pointer;
}

.cancel-icon-order-cash {
  position: relative;
  left: 360px;
  background: none;
  border: none;
  cursor: pointer;
}

.Delete_btn_order{
  justify-content: space-between;
  width: 80%;
  display: flex;
  margin-top: 8px;

}

.Deleted_details_order{
  display: grid;
  width: 50%;
  height: 80px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
}
.warning_message{
  color: red;
  font-weight: bold;
  margin-top: 8px;
}
.close_order{
  margin-left: 500px;
  position: relative;
  bottom: 45px;
  left: 34px;
  cursor: pointer;
}
.header_divpart{
  height: 50px;
}
.header_divpart h1{
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-order_header{
  display: flex;
  padding: 10px;
}
.input_textfield_order{
  display: flex;
  width: 85%;
}


.search_item_order .MuiOutlinedInput-input::-webkit-input-placeholder {
  font-size: 16px;
  color: black;
}
.search_item_order .MuiOutlinedInput-input:-moz-placeholder {
  font-size: 16px;
  color: #c0a6a6;
}
.search_item_order .MuiOutlinedInput-input::-moz-placeholder {
  font-size: 16px;
  color: #301919;
}
.search_item_order .MuiOutlinedInput-input:-ms-input-placeholder {
  font-size: 16px;
  color: #ccc;
}

.payment-methods-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -20px;
}

.payment-methods-container .MuiFormControlLabel-root {
  display: flex;
  cursor: pointer;
  margin-right: 2px;
  height: 45px;
  width: 100px;
  align-items: center;
}

.payment-methods-container .MuiRadio-root {
  transform: scale(0.8);
}

.payment-methods-container .MuiFormControlLabel-root.Mui-disabled {
  cursor: not-allowed;
  color: #b0b0b0;
}

.payment-methods-container label {
  margin-left: 5px;
  font-size: 14px;
}

.payment_type {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.header-product-structure-category-ordersearch{
  margin-top: 15px;
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: flex-end;
  width: 88vw;
  height: 60px;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  gap: 15px; 
}
