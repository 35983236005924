/* .full_length{
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  } */

/* .ledger_body {
    width: 100vw !important;
    height: 90vh !important;
    overflow: auto !important;
    background-color: rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    padding-top: 20px;
} */

data {
    background-color: white;
    width: 800px;
    height: fit-content;
}

.ledger_body ul {
    display: flex;
    margin: 10px 5px 2px 5px;
    border: 1px solid black;
    border-radius: 20px;
    height: 60px;
    width: 790px;
    position: static;
}

.ledger_body ul li {
    font-size: large;
    font-weight: bold;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ledger_body ol {
    display: flex;
    margin: 2px 5px 2px 5px;
    border: 1px solid black;
    border-radius: 20px;
    height: 60px;
    width: 790px;
}

.ledger_body dl {
    font-size: large;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ledger_body p {
    width: 100px;
}

.ledger_body ol:hover {
    background-color: beige;
    cursor: pointer;
}

.customer_ledger_body {
    width: 100vw !important;
    height: 90vh !important;
    overflow: auto !important;
    background-color: rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.customer_ledger_body data {
    background-color: white;
    width: 750px;
    height: fit-content;
    border-radius: 10px;

}

.customer_ledger_body ul {
    width: 790px;
    height: 190px;
    display: flex;
}

.customer_ledger_body ul li {
    width: 370px;
    margin: 2px;
    padding: 2px;
    border: solid 1px black;
    border-radius: 10px;
}

.customer_ledger_body ul li p {
    margin-left: 20px;
    width: 300px;
    font-weight: bolder;
    font-size: 16px;
}

.customer_ledger_body ul li div {
    margin: 10px;
    display: flex;
}

.customer_ledger_body ul li h1 {
    color: red;
    width: 180px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.customer_ledger_body ol {
    width: 750px;
    height: 60px;
    display: flex;
    border: .5px solid black;
    margin-top: 2px;
    margin: 1px;
}

.customer_ledger_body ol dl {
    width: 125px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.customer_ledger_body div button {
    display: flex;
    justify-content: center;
    width: 150px;
    height: 20px;
    border-radius: 20px;
    margin: 10px;
    background-color: blue;
    color: white;
}

.payment {
    position: fixed;
    background-color: rgba(67, 67, 67, 0.2);
    top: 0px;
    width: 97.3%;
    height: 100%;
    z-index: 100;
    display: flex;
    flex-direction: row-reverse;
}

.payment div {
    margin-right:30px;
    width: 350px;
    flex-wrap: wrap;
    height: 90vh;
    margin-top: 60px;
    background-color: white;
    border-radius: 10px;
    padding: 10px 5px 10px 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.payment ul {
    display: flex;
    flex-direction: column;
    margin: 10px 5px 2px 5px;
    padding: 10px 5px 2px 5px;
    border: 1px solid black;
    border-radius: 20px;
    height: 150px;
    width: 273px;
    position: relative;
    right: 5px;
    left:9px;
}

.payment li {
    width: 250px;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.payment p input {
    cursor: pointer;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    border: 1px solid black;
    transition: all 0.3s ease;
    text-align: center;
    background-color:white;
    font-weight: bold;
}
.payment p input:active{
    background-color: red;
    border-color: red;
    color:white;
}

.Payments_section input {
    width: 100px;
    height: 40px;
    border-radius: 10px;
    font-size: 1.5rem;
    padding: 5px;
}

.Payments_section ul {
    height: 23vh;
    background-color: rgb(244, 249, 249);
}

.Payments_section button {
    background-color: #719df3;
    color: white !important;
    width: 10vw;
    height: 5vh;
    border-radius: 20px;
    font-size: larger;
    cursor: pointer;
    margin-right: 10px;
    border: none;
}

.payment div h2 {
    /* background-color: blueviolet; */
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
}

.view_button {
    width: 60%;
    height: 30px;
    border-radius: 20px;
    cursor: pointer;
}

.Ledger_list {
    width: 500px;
    align-items: start;
    height: 315px;
    background-color: rgb(244, 249, 249);
    margin-top: 50px;
    overflow: hidden;
    border: 1px solid black;
    margin-left: 10px;
    border-radius: 10px;
}

.Ledger_list h1 {
    text-align: center;
    margin-top: 20px;
    margin-right: 160px;
}

.Ledger_list h3 {
    margin-left: 80px;
}
.table_body {
    text-align: center;
}

.MuiTableCell-root {
    padding: 8px;
    text-align: center;
}

.MuiTableHead-root .MuiTableCell-root {
    font-weight: bold;
}

.table_body tr {
    transition: background-color 0.3s ease;
}

.table_body tr:hover {
    background-color: #ebf1eb;
    cursor: pointer;
}

.balance_customer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7%;
}

.ledger_first {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    width: 100%;
    max-width: 1100px;
    margin: 20px auto;
    gap: 20px; 
    margin-left: 40px;
}

.ledger_second, .ledger_third {
    display: flex;
    flex-direction: column;
    flex: 1 1 45%; 
    min-width: 280px;
    border: 1px solid black;
    border-radius: 10px;
    background-color: rgb(244, 249, 249);
    padding: 20px;
    box-sizing: border-box; 
    height: auto;
    overflow: hidden; 
}

.ledger_second h2, .ledger_third h2 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.ledger_second ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.ledger_second h3 {
    margin: 5px 0;
}

.out_bala h2 {
    font-size: 2rem;
    text-align: center;
    margin: 0;
}

.button_alignment {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 15px;
}

.ptint_button_ledger, .pay_button_ledger {
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    background-color: #7ee185;
    height: 40px;
    width: 12rem;
    cursor: pointer;
    font-size: 1.2rem;
}

.ptint_button_ledger:hover, .pay_button_ledger:hover {
    background-color: #5ad065;
}


@media (max-width: 768px) {
    .ledger_first {
        flex-direction: column; 
        align-items: center; 
    }

    .ledger_second, .ledger_third {
        width: 90%; 
        max-width: none; 
    }

    .out_bala h2 {
        font-size: 1.5rem; 
    }

    .button_alignment {
        flex-direction: column;
        gap: 10px;
    }
}

@media (min-width: 1150px) {
    .balance_structure {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        height: auto;
        font-size: 1.8rem;
    }

    .out_bala h2 {
        font-size: 2.5rem; 
    }
}


.category-add {
    display: flex;
    flex-direction: row-reverse;
    width: 89vw;
}

.Ledger_head {
    width: 100%;
}

.Ledger_body {
    width: 270px;
    position: relative;
    left: 200px;
    overflow: hidden;
}

.heading_point {
    width: 60vw;
    height: 70px;
}

.overall_cointainer {
    display: flex;
    width: 90vw !important;
    /* height: 90vh !important; */
    /* background-color: red; */
}

.different_container {
    display: flex;
    width: 35vw;
    height: 80vh;
    max-width: 1000px;
    min-width: 550px;
}

.cointainer2 {
    width: 88vw;
    height: auto;
    /* height: 80vh; */
    /* background-color: #7ee185; */
}

.full_length {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* overflow: hidden; */
    height: auto;
}

.header_point {
    width: 100%;
    height: auto;
}

.pointer_sale {
    display: flex;
    width: 74%;
    background-color: green;
    height: 40%;
    column-gap: 3%;
}

.coustomer_page {
    display: flex;
    background-color: rgb(51, 0, 255);
    width: 48%;
}

.outing_page {
    background-color: brown;
    width: 48%;
}

.table_page {
    background-color: yellow;
    width: 74%;
    height: auto;
}

.date {
    background-color: bisque;
    display: flex;
    width: 55%;
}

.name_folder {
    background-color: aquamarine;
    width: 30%;
}

.Name_details li {
    display: flex;
}

.Name_details li h3 {
    width: 150px;
    display: flex;
    height: auto;
}

@media(min-width:768px){
.out_bala {
    display: flex;
    width: 100%;
    grid-template-columns: 2*2;
    flex-wrap: wrap;
    color: red !important;
}
}
.category-addledger {
    display: flex;
    flex-direction: row-reverse;
    width: 89vw;
}
.leder_header_sec {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between;
    width: 100%;
    margin-left: 35px;
    margin-top: 20px;
}

.total_records, .oustand_bala {
    flex: 1; 
    min-width: 200px; 
    background-color: #ececec;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    text-align: center; 
}

.backend_total, .backend_total_bala {
    font-size: 2rem;
    margin: 0;
    text-align: center; 
}

@media (max-width: 768px) {
    .total_records, .oustand_bala {
        flex: 1 1 100%; 
        text-align: left; 
    }
}
.search_item{
    height: 60px;
    margin-top: 30px;
}
.search_item_order{
    display: flex;
    height: 63px;
    margin-top: 18px;

}
.customer_Table_ledger {
    width: 95vw;
    display: flex;
    align-items: start;
    height: auto;
    overflow: auto;
    margin-bottom: 20px;
  }
  .no-balance-message {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 16px;
    margin: 10px 0;
    border-radius: 4px;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
  }
  
  .Payments_section h1 {
    color: #2c3e50;
    margin-bottom: 15px;
  }
  
  .search_item .MuiOutlinedInput-input::-webkit-input-placeholder {
    font-size: 16px;
    color: black; 
  }
  
  .search_item .MuiOutlinedInput-input::-moz-placeholder {
    font-size: 16px;
    color: black; 
  }
  
  .search_item .MuiOutlinedInput-input:-ms-input-placeholder {
    font-size: 16px;
    color: black; 
  }
  
  .search_item .MuiOutlinedInput-input::placeholder {
    font-size: 16px;
    color: black; 
  }