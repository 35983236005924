.order-no {
    display: flex;
    height: 35px;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: large;
}

.custom-table-container {
    width: 100%;
    max-width: 800px;
    height: 560px;
    margin: 20px;
    border-radius: 5px;
    background-color: #fff;
    overflow: auto;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    border-radius: 15px;
}

.custom-table thead {
    background-color: #4204ae;
    color: #ffffff;
    text-transform: uppercase;
}

.custom-table thead th {
    padding: 15px;
    font-size: 14px;
    font-weight: bold;
}

.custom-table tbody tr {
    border-bottom: 1px solid #ddd;
}

.custom-table tbody tr td {
    padding: 15px;
    font-size: 14px;
    color: #333;
    word-wrap: break-word;
}

.custom-table tbody tr:hover {
    background-color: #f9f9f9;
}

.custom-table tbody tr:nth-child(even) {
    background-color: #f4f4f4;
}

@media screen and (max-width: 768px) {
    .custom-table-container {
        width: 95%;
        margin: 10px auto;
    }

    .custom-table thead th,
    .custom-table tbody td {
        padding: 10px;
        font-size: 12px;
    }
}

.pay-cancel-btn {
    display: flex;
    margin-left: 80px;
    margin-bottom: 20px;
    background-color: #ff4d4d;
    width: 774px;
    height: 50px;
    border-radius: 11px;
    font-size: 12px;
    color: #000000;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.payment-section {
    background-color: #f9f9f9;
    padding: 20px;
    border-left: 1px solid #ddd;
    width: 419px;
    height: 650px;
}

.payment-box-1 {
    width: 395px;
    height: 65px;
}

.payment-box-1 h4 {
    font-size: 14px;
    margin-bottom: 10px;
}

.payment-box-1 h2 {
    color: orange;
    font-size: 25px;
    margin-top: -10px;
}

.payment-box-1 p {
    display: flex;
    width: 101.68px;
    height: 10px;
    align-items: center;
    color: #888;
    font-size: 14px;
    margin: 10px;
    padding: 0;
}

.payment-section .tip-buttons {
    margin-bottom: 5px;
}

.tip-buttons button {
    margin: 5px;
    padding: 10px 20px;
}

.tip-buttons .MuiButton-contained {
    background-color: #EAF0F0;
    color: rgb(19, 19, 19);
}

.tip-buttons .MuiButton-outlined {
    border: 1px solid #FAFAFA;
    color: rgb(16, 15, 15);
}

.payment-options {
    width: 385px;
    height: 70px;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-bottom: 20px;
}

.payment-options-btn {
    display: flex;
    height: 50px;
    justify-content: space-evenly;
    margin-right: 10px;
}

.icon-button-box {
    display: inline-block;
    width: 85px;
    height: 58px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 9px;
    background-color: #EAF0F0;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
}

.icon-button-box:hover {
    background-color: #EAF0F0;
}

.custom-line {
    border: none;
    border-top: 2px solid #ccc;
    margin: 10px 0;
    width: 100%;
}

.cash-received-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 380px;
    height: 60px;
    padding: 10px 20px;
    background-color: #EAF0F0;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
}

.cash-received-container span {
    font-size: 13px;
    font-weight: bold;
}

.cash-amount {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cash-amount span {
    font-size: 15px;
    font-weight: bold;
}

.total-summary {
    display: flex;
    flex-direction: column;
    width: 380px;
    height: 60px;
    font-weight: bold;
    font-size: 15px;
}

.total-summary dt {
    display: flex;
    justify-content: space-between;
}

.total-summary dt h4 {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.total-summary dt p {
    display: flex;
    margin: 0;
    padding: 0;
    width: 150px;
    flex-direction: row-reverse;
}

.total-payment {
    display: flex;
    width: 380px;
    height: 50px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    align-items: center;
    font-size: large;
}

.pay-now-btn {
    display: flex;
    margin-top: 10px;
    background-color: #8BB5BE;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.paynow-enable-btn {
    display: flex;
    width: 380px;
    height: 60px;
    background-color: #8bb5be;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.paynow-enable-btn.disabled {
    background-color: gray;
    cursor: not-allowed;
}

.Print_popup_restarunt {
    position: fixed;
  top: 0%;
  left: 0%;
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: auto;
  border-radius: 3px;
  background-color: aliceblue;
  font-size: 14px;
  color: black;
}

.payment-container-1 {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 20px;
    height: 700px;
    overflow-y: auto;
    background-color: #f9f9f9;
}