.Overall_cart {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  overflow: auto;
  position: relative;
  width: 95vw;
  height: 90vh;
  padding-top: 10px;
}

.icon-btn-edit {
  background: none;
  border: none;
  margin-top: 6px;
  margin-right: 7px;
}

.shop_delete {
  display: flex;
  width: 100%;
  margin-top: 15px;
}

.Cartname {
  height: 40px;
  width: 95vw;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #d9d9d980;
  padding-left: 20px;
}

.Cartdisplayoverall {
  display: flex;
  max-width: 1500px;
  width: 95vw;
  min-width: 800px;
  height: 90vh;
  overflow: auto;
}

.cartsavedata {
  background-color: rgba(219, 219, 219, 0.941);
  width: 300px;
  height: 150px;
  margin: 10px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: grid;
}

.productcartlist {
  display: grid;
  grid-template-columns: auto auto auto;
  width: 980px;
  height: 600px;
  background-color: rgba(255, 228, 196, 0.615);
  overflow: auto;
}

.check-btn {
  color: green;
  margin-left: -60px;
  margin-top: 10px;
  width: 25px;
  height: 5px;
  position: relative;
  top: 30px;
}

@media(min-width: 1100px) {
  .Product_list {
    width: 70vw;
    height: 90vh;
  }
}

.Porducts {
  display: flex;
  height: 70px;
  background-color: antiquewhite;
  justify-content: space-between;
  margin: 10px;
  border-radius: 3px;
  align-items: center;
}

.Porducts_details {
  display: flex;
  height: 70px;
  background-color: antiquewhite;
  justify-content: space-between;
  margin: 10px;
  border-radius: 3px;
  align-items: center;

}

.Porducts li {
  width: 140px;
  display: flex;
  justify-content: center;
}

.Order_details {
  max-width: 350px;
  width: 23vw;
  min-width: 240px;
  height: 250px;
  border-radius: 3px;
  border: dotted 2px #4b494974;
  padding: 2px;
  padding-left: 5px;
  margin-top: 5px;

}

.Order_details li {
  margin-top: 10px;
  display: flex;
  max-width: 330px;
  width: 20vw;
  min-width: 220px;
  justify-content: space-between;
}

.grand_total {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  width: 20vw;
  min-width: 220px;
  height: 40px;
  border-radius: 3px;
  padding: 5px;
  margin-top: 5px;
  overflow: hidden;
}

.Customer_Details {
  margin-top: 5px;
  padding-left: 5px;
  max-width: 350px;
  width: 23vw;
  min-width: 240px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(234, 232, 230, 0.515);
  cursor: pointer;
}

.customer_selection {
  max-width: 350px;
  width: 23vw;
  min-width: 240px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: dotted 2px #4b494974;
  padding: 10px;
}

.customer_selection .label {
  font-weight: normal;
}

.customer_selection li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  max-width: 350px;
  width: 22vw;
  min-width: 240px;
}

.customer_selection p {
  width: 180px;
  font-weight: bold;
}

.payment_selection {
  max-width: 350px;
  width: 23vw;
  min-width: 240px;
  height: 120px;
  display: grid;
  align-items: center;
  justify-content: center;
  border: dotted 2px #4b494974;
  padding-left: 8px;
  overflow: hidden;
}

.payment_selection ul {
  display: flex;
  max-width: 350px;
  width: 23vw;
  min-width: 240px;
  justify-content: space-between;
  font-size: larger;
  padding: 5px;
}

.payment_selection li {
  width: 100px;
}

.confirm {
  max-width: 350px;
  width: 22vw;
  min-width: 220px;
  display: flex;
  justify-content: center;
}

.inputqty {
  width: 40px;
  background: none;
  border: none;
  font-size: larger;
  padding-left: 10px;
}

.model,
.overlay {
  width: auto;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.customer_selection_pop {
  position: fixed;
  top: 48%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  padding: 14px 28px;
  background: #f1f1f1;
  border-radius: 15px;
  width: 600px;
  height: 580px;
}

.customer_selection_pop ul {
  display: flex;
  width: 320px;
  justify-content: space-between;
  margin: 5px;
}

.popup-custmer {
  width: 450px;
  height: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.customer_data ul {
  display: flex;
  width: 320px;
  justify-content: space-between;
  margin: 5px;
  cursor: pointer;
}


.customer_data ul:hover {
  border: 1px solid var(--colors);
  border-radius: 10px;
  padding: 5px;
  color: var(--colors);
}


.customer_data {
  display: flex;
  width: 530px;
  height: 350px;
  padding: 5px;
  overflow-y: scroll;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.customer-title {
  width: 500px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
}

.Addcustomer {
  position: fixed;
  top: 48%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  padding: 14px 28px;
  background: #f1f1f1;
  border-radius: 15px;
  width: 580px;
  height: 540px;
}

.Addcustomer ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.Addcustomer li {
  display: grid;
  width: 380px;
  border: solid 1px #4b494974;
  padding: 5px;
}

.button_class {
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  width: 480px;
  margin-right: 15px;;
}

.payment_popup {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  border-radius: 3px;
  width: 400px;
  height: 600px;
}

.confirmhead {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  width: 350px;
  height: 150px;
  border-radius: 10px 10px 20px 20px;
}

.confirmbody {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 15px;
  max-width: auto;
  min-width: 300px;
  max-height: 600px;
}

.icons_pay {
  color: var(--colors);
  background-color: rgb(255, 255, 255);
  border-radius: 40px;
}

.Payconfirm_Icon {
  background-color: var(--colors);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-top: -75px;
}

.confirmbody ul {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.confirmbody p {
  margin-left: 20px;
}

.confirmbody button {
  margin: 20px;
  font-size: x-large;
  width: 300px;
  padding: 10px;
  border-radius: 20px;
  background-color: var(--colors);
  color: #f1f1f1;
  border: var(--colors);
  cursor: pointer;
}

.payment_amount {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 130px;
  margin-bottom: 10px;
  margin-top: 5px;
  border: 1px solid #33333397;
  border-radius: 20px;
  padding: 4px;
}

.payment_amount dt {
  display: flex;
  width: 275px;
  justify-content: space-between;
}

.payment_amount dt h5 {
  display: flex;
  width: 140px;
  margin-left: 15px;
  justify-content: space-between;
}

.payment_amount dt p {
  display: flex;
  justify-content: space-between;
}

.payment_amount input {
  width: 50px;
  border-radius: 5px;
  font-size: larger;
}


.payment_amount_cash {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 175px;
  margin-bottom: -100px;
  margin-top: 5px;
  border: 1px solid #33333397;
  border-radius: 20px;
  padding: 4px;
  background-color: red;
}

.payment_amount_cash dt {
  display: flex;
  width: 290px;
  justify-content: space-between;
}

.payment_amount_cash dt h5 {
  display: flex;
  width: 140px;
  margin-left: 24px;
  justify-content: space-between;
}

.payment_amount_cash dt p {
  display: flex;
  width: 90px;
  justify-content: end;
}

.payment_amount_cash input {
  display: flex;
  width: 60px;
  border-radius: 5px;
  font-size: larger;
  text-align: end;

}

.mode_of_payment {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 40px;
  color: #372e2e;
  background-color: rgba(124, 120, 115, 0.505);
  border-radius: 10px;
  margin-top: 5px;
}

.confirmbodys {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  width: 350px;
  height: 450px;
  border-radius: 20px 20px 10px 10px;
  border-top: 1px dashed #1e1818f5;
}

.Cartid_delet {
  display: flex;
  max-width: 350px;
  width: 22vw;
  min-width: 240px;
  justify-content: space-between;
}

.Cartid_delet button {
  background: none;
  border: none;
  cursor: pointer;
}

.Paysuccess_Icon {
  background-color: rgb(17, 174, 9);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-top: -75px;
}

.icons_success {
  color: rgb(17, 174, 9);
  background-color: rgb(255, 255, 255);
  border-radius: 40px;
}

.confirmbodys li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 30px;
}

.confirmbodys ul {
  padding: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 320px;
  height: 150px;
  border: 1px solid rgba(45, 45, 45, 0.537);
  border-radius: 10px;
}

.confirmbodys h2 {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: x-large;
  font-style: italic;
  color: var(--colors);
  margin-bottom: 10px;
}

.Confirm_Print_mail {
  display: flex;
  justify-content: center;
  width: 300px;
  margin-top: 10px;
}

.Confirm_Print_mail button {
  margin: 3px;
  width: 100px;
  background-color: var(--colors);
  color: #f1f1f1;
  border-radius: 10px;
  padding: 2px;
}

.buttonon {
  margin: 3px;
  width: 300px;
  height: 40px;
  background-color: var(--colors);
  color: #f1f1f1;
  border-radius: 10px;
  padding: 2px;
}

.customer_selection_cart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 20vw;
  height: 100px;
  border-radius: 20px 20px 10px 10px;
}

.customer_selection_cart ul {
  max-width: 350px;
  width: 20vw;
  min-width: 240px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.customer_selection_cart li {
  max-width: 350px;
  width: 20vw;
  min-width: 240px;
  display: flex;
  justify-content: flex-start;
}

.confirm_delete {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  border-radius: 3px;
  width: 400px;
  height: 150px;
  background-color: #f1f1f1;
}

.confirm_delete ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.confirm_delete li {
  display: flex;
  width: 350px;
  justify-content: space-around;
  font-size: x-large;
  margin: 10px;

}

@media(min-width:1100px) {
  .datashown {
    display: none;
  }
}

@media (max-width: 1100px) {
  .hide-on-small {
    display: none;
  }

  .ondisplay {
    background-color: #1c1c1c28;
  }

  .modelcart,
  .overlaycart {
    width: auto;
    height: auto;
    top: 8.5%;
    /* left: 0; */
    right: 2px;
    bottom: 0;
    position: fixed;
    display: flex;
    justify-content: end;
    padding-left: 5px;
    background-color: white;
    z-index: 2;
    /* align-items: flex-end; */
  }

  .hidden_overlay {
    background-color: white;
    width: 33vw;
    margin-left: 2vw;
  }

  .overlay_hidden {
    position: fixed;
    top: 8.5%;
    /* margin-top: 10vh; */
    left: 62vw;
    width: 30px;
    height: 30px;
    border-radius: 40px;
    border: 2px solid var(--colors);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay_vissible {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 20px;
    position: fixed;
    right: 2px;
    border: 2px solid var(--colors);
    cursor: pointer;
  }

  .Product_list {
    width: 90vw;
    height: 90vh;
  }
}

@media (max-width: 1100px) {
  .Order_details {
    height: auto;
  }
}

.aeros {
  display: flex;
  align-items: center;
  width: 600px;
  position: relative;
  bottom: 30px;
}

.cart-select-customer {
  width: 250px;
  margin-left: 60px;
}

.cart-cus-name {
  font-size: 18px;
  width: 100%;
  margin-left: 10px;
}

.cart-cus-ph {
  display: flex;
  font-size: 18px;
  width: 100%;
  flex-direction: row-reverse;
}

.cart-cus {
  display: flex;
  margin: 1px;
  width: 480px;
  height: 40px;
  font-weight: 100;
  justify-content: space-between;
}

.cart-cus:hover {
  background-color: rgb(252, 247, 247);
  border: 1px solid rgba(93, 92, 92);
  border-radius: 10px;
  padding: 2px;
  font-weight: bold;
  height: 40px;
}

.Inputuser-cart {
  width: 350px;
  height: 70px;
  margin: 20px;
}

.Aaddres-cart {
  display: flex;
  flex-direction: column;
}

.input-address-cart {
  margin-top: 15px;
}

.input-address-cart ul {
  border: solid 1px #a09d9dd3;
  padding: 8px;
  display: grid;
  border-radius: 5px;
  width: 480px;
  margin-right: 10px;
}