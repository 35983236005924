.mainbody_home {
  background-image: url("../../../assets/Images/car-wash-background.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.body_alignment {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 90vw;
  max-width: 1000px;
  margin: 190px auto;  
}

.content {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.content:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.icon-container svg {
  height: 60px;
  width: 60px;
  color: #3f51b5;
}

.Order_list_tag {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.Order_list_tag,
.Order_list_tag_currentorder,
.Order_list_tag_ligth,
.Order_list_tag_setting,
.Order_list_tag_profile {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  text-align: center;
}

@media (max-width: 768px) {
  .body_alignment {
    flex-direction: row;
    gap: 15px;
  }
  .content {
    width: 90%;
  }
}


.carbody_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: auto;
  background-color: #f4f4f4;
}

.home-container {
  background-image: url("../../../assets/Images/car-wash-background.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.content_container {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  margin-left: 110px;
  margin-top: 20px;
  width: 80%;
  height: 605px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
}

.collecting_details{
  margin-top: 130px;
}
.collecting_details h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
}

.collecting_details p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
}

.cardetails_collecting {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  width: 100%;
  max-width: 600px;
}

.cardetails_collecting input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input::placeholder {
  font-size: 16px; 
  display: flex;
  justify-content: flex-start; 
  align-items: flex-start; 
  /* line-height: normal;  */
  margin: 0;
  padding: 0;
  margin-left:80px;
}

input {
  width: 100%; 
  height: 40px; 
  padding: 5px 10px; 
  box-sizing: border-box;
}

.cardetails_collecting input:focus {
  border-color: #4CAF50;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

.services_container {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.order_button{
  background-color: rgb(91, 223, 133);
  height: 40px;
  width: 150px;
  border: none;
  border-radius: 15px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 22px;
  color: white;
}

.service_card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 180px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service_card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.service_image {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

.service_card h2 {
  font-size: 1.2rem;
  color: #333;
}

.Car-button{
  background-color: green;
  height: 40px;
  width: 140px;
  border-radius: 10px;
  border: none;
  font-size: 100px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  outline: none;

}
