.app {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 60px;
  width: 99%;
  background-color: #f8f8f8;
}

.logout-button {
  background-color: #f14545;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.logout-button:hover {
  background-color: #c12f2f;
}
.head_style{
  width: 18%;
}

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.food-grid-restarunt {
  display: grid;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  max-height: 500px;
  overflow-y: scroll;
  padding: 20px;
  width: 100%;
  height: 0%;
  border: 1px solid #ccc;
  height: auto;
}

.food-items {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 250px; 
  height: auto;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 15px;
  background-color: #f7fafa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.food-items:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.food-items img {
  border-radius: 20px 20px 0 0;
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.food-items ul {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.food-items li {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.handle-design {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.handle-design span {
  font-size: 18px;
  font-weight: 600;
  color: #444;
}

.handle-design .MuiIconButton-root {
  background-color: #e0f7fa;
  color: #00796b;
  transition: background-color 0.2s ease;
}

.handle-design .MuiIconButton-root:hover {
  background-color: #b2dfdb;
}

.content-restarunt {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
}

.food_alignment-restarunt {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  background-color: #fefefe;
}

.search-input {
  width: 400px;
  height: 48px;
  background-color: #f9fbfc;
  border-radius: 25px;
}

.search-input .MuiOutlinedInput-root {
  border-radius: 25px;
}

.search-input .MuiOutlinedInput-root fieldset {
  border-color: #cfd8dc; 
}

.search-input .MuiOutlinedInput-root:hover fieldset {
  border-color: #90caf9; 
}

.search-input .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #42a5f5;
}

.search-input .MuiInputLabel-root {
  color: #90a4ae; 
}

.search-input .MuiInputLabel-root.Mui-focused {
  color: #42a5f5; 
}

.search-input .MuiOutlinedInput-input {
  padding: 12px 16px; 
}

.order-summary {
  width: 250px;
  background-color: #f1f1f1;
  padding: 20px;
}

.order-summary ul {
  list-style: none;
  padding: 0;
}

.create-order-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: blue;
  color: white;
  border: none;
  cursor: pointer;
}

.reshead {
  display: flex;
  height: 60px;
  width: 100vw;
  justify-content: space-between;
  padding-left: 100px;
  align-items: center;
  background-color: #ebe7e7;

  /* background-color:var(--background-color);
  font-family: var(--fontfamily); */
}

.com_reshead {
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #ffffff;
}

@media (min-height: 700px) {
  .Res_menu_container {
    display: flex;
    flex-direction: column;
    transition: width 0.3s, border-radius 0.2s;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
}

@media (max-height: 700px) {
  .Res_menu_container {
    display: none;
  }
}

@media (max-width: 768px) {
  .Res_menu_container {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 1024px) {
  .Res_menu_container {
    width: 100%;
    height: 100%;
  }
}

.menu_Res_select {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  width: 115px;
  height: 65px;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  transition: transform 0.3s;
}

.menu_Res_select p {
  color: var(--color);
  width: 110px;
  font-size: small;
  font-weight: bolder;
  font-family: var(--fontfamily);
}

.menu_Res_select:hover {
  width: 115px;
  overflow: hidden;
  transform: scale(1.0);
}

.resmenuicons {
  z-index: 1000;
  bottom: 10px;
  margin-left: 5vw;
  right: 50px;
  justify-content: flex-start;
  align-items: flex-end;
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.res-overlay-content {
  z-index: 1000;
  background: #e1e1e1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-left: 5vw;
  top: 10px;
  box-sizing: border-box;
  position: relative;
  height: auto;
}

@media (max-width: 300px) {
  .table_head {
    flex: 1 1 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 5px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    margin-right: 50%;
  }
}

.table_lable {
  font-size: 17px;
  color: #333;
}

.table_select {
  padding: 8px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  color: #333;
  cursor: pointer;
}

.table_select:focus {
  border-color: #007bff;
  outline: none;
}

.table_select option {
  padding: 10px;
}

/* @media (max-width: 1024px) { */
  .running_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 20px;
    background-color: #f1f1f1;
    border-radius: 8px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    max-height: 200px;
    position: relative;
    left: 220px;
  }
/* } */


.running_label {
  font-size: 17px;
  color: #333;
}
@media (min-width: 768px) {
.running_select {
  padding: 8px 12px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  color: #333;
  cursor: pointer;
  transition: border-color 0.3s ease;
}
}

.running_select:focus {
  border-color: #007bff;
  outline: none;
}

.running_select option {
  padding: 10px;
}

.home_page_overall {
  width: 100%;
  height: auto;
  
}


.search_content {
  width: 70%;
  height: 660px;
  /* background-color: rgb(128, 0, 49); */
}

.order_home {
  width: 28%;
  margin-left: 20px;
  /* background-color: #4CAF50; */
}

.food_alignment {
  background-color: #fefefe;
  height: 70%;
  flex-wrap: wrap;
}

.food_buttons {
  height: auto;
  margin-top: 1px;
  background-color: #e6f9fb;
  
}

.food_select {
  display: flex;
  gap: 5px;
  display: auto;
  flex-wrap: wrap;
  justify-content: flex-start; 
  align-items: center;
  grid-template-columns: repeat(7, 1fr);
  margin-left: 10px;
}

.food_select button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.food_select button:hover {
  background-color: #45a049;
}

.food_select button:active {
  background-color: #3e8e41;
}

.order_details_home {
  display: flex;
  margin: 0;
  height: 100px;
  border-bottom: 2px dotted black;
  background-color: red;
}

.order_items_home {
  background-color: #007bff;
}

.toogle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  /* margin-bottom: 1rem; */
}

.order_inline_align {
  display: flex;
  align-items: center;
  /* padding: 0.5rem 1rem; */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order_inline_align ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.order_inline_align ul li {
  /* margin: 5px 0; */
}

.input_alignment {
  /* background-color: #ebe7e7; */
  height: auto;
  height: 355px;
  padding: 20px;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.input_alignment input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.bill_order {
  border: 2px dotted rgb(161, 159, 159);
  border-radius: 8px;
  height: 190px;
  font-size: 17px;
  padding: 10px;
}

/* .bill_order {
  margin: 10px 0; 
}

.bill_order p {
  margin: 0; 
  padding: 5px 0; 
} */

.bill_order h3 {
  margin: 10px 0;
}

.btn_send {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  width: auto;
 position: relative;
 bottom: 20px;
}

.btn_send button {
  margin-top: 10px;
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}
@media (min-width: 768px){
.search_home {
  display: flex;
  justify-content: end;
  margin-top: 9px;
  height: 60px;
}
}
@media (min-width: 968px){
.search_home ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  margin: 0;
  padding: 0;
  width: 35%;
  margin-right: 80px;
  list-style-type: none;
  /* background-color: #f5f5f5; */
  border-radius: 8px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  transition: all 0.3s ease;
}
}
.search_home ul li {
  padding: 10px 20px;
  cursor: pointer;
}

.search_home ul li:hover {
  /* background-color: #e0e0e0; */
  transform: scale(1.05);
}

/* .search_home p {
  width: 12%;
  font-size: larger;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333; 
  background-color: #fafafa; 
  padding: 10px 0; 
  border-radius: 5px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.search_home p:hover {
  background-color: #ddd; 
  color: #000; 
  transform: scale(1.05); 
} */
.handle-design {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  width: 70%;
  margin-left: 25px;
  border-radius: 20px;
}

.handle-design :hover{
  background-color: #739ed7;
  border-radius: 25px;
}
.order_name{
  width: 50%;
  font-size: 18px;
}
.order_quentity{
  width: 10%;
}
.order_na_quen{
  display: flex;
  justify-content: space-between;
}
.order_quentity ul li{
  display: flex;
  justify-content: end;
  font-size: 18px;
}
.input_image{
  background-color: #007bff;
  display: flex;
}
.take_away{
  background-color: rgb(216, 211, 211);
  height: 30px;
  display: flex;
  justify-content: space-between;
}
.take_away button{
  border: none;
  width: 50%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.active-category {
  background-color: blue !important;
  color: white !important;
  border-radius: 5px;
  padding: 8px 15px;
  transition: background-color 0.3s ease;
}

/* styles.css */
.dialogPaper {
  width: 400px;
  max-width: none;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.dialogTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

.dialogContent {
  text-align: center;
  padding: 20px;
}

.dialogMessage {
  font-size: 18px;
  margin-bottom: 20px;
  color: #555;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.cancelButton {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  background-color: #f0f0f0;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.printButton {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.dropdown-content-restaruent {
  position: absolute;
  top: 40px; 
  right: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 150px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.dropdown-content-restaruent.show {
  visibility: visible;
  opacity: 1;
}

.dropdown-content-restaruent a {
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.dropdown-content-restaruent a:hover {
  background-color: #f4f4f4;
  color: #007bff;
}

.heder_profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 120px;
}

.heder_profile:hover .dropdown-content-restaruent {
  visibility: visible;
  opacity: 1;
}

#logout {
  color: #F14545;
  font-weight: bold;
}

.heder_profile svg {
  font-size: 30px;
  color: #000;
}

@media print {
  body * {
    visibility: hidden;
  }
  .print-section,
  .print-section * {
    visibility: visible;
  }
  .print-section {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

.delivery{
  position: relative;
  right: 27px;
  color: green;
}
.table{
  color: blue;
  position: relative;
  left: 12px;
}

@media print {
  body * {
    visibility: hidden;  /* Hide everything */
  }
  #print-section, #print-section * {
    visibility: visible; /* Show only print content */
  }
  #print-section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.Print_popup_restarunt_home{
  position: fixed;
  top: 0%;
  left: 0%;
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  height: auto;
  border-radius: 3px;
  background-color: aliceblue;
  font-size: 14px;
  color: black;
}