.car-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.left-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1px;
}

.business-logo {
  width: 300px;
  height: 60px;
  object-fit: contain;
  border-radius: 30px;
  background-color: hsl(28, 46%, 90%);
}

.organiztion-name {
  display: flex;
  flex-direction: column;
  margin: -40px 50px 50px 70px;
  font-size: 2.5r em;
  font-weight: 600;
  color: #333;
}

.business-name {
  font-size: 1.1r em;
  font-weight: 600;
  color: #333;
}

.right-section {
  display: flex;
  align-items: center;
  margin-left: 30%;
}

.powered-by-logo {
  height: 40px;
  object-fit: contain;
}

/* .payment-section{
  width: 100vw;
  height: auto;
} */

.payment-bar {
  width: 60%;
  height: 70px;
  background-color: #f9f9f9;
  border-radius: 15px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-content ul {
  display: flex;
  margin-right: 70px;
  justify-content: space-between;
  width: 100%;
  height: 55px;
  padding: 0;
  list-style-type: none;
  font-size: 40px;
}

.radio-inline {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333;
  margin: 0 20px; 
}

.radio-inline input {
  margin-right: 8px;
}

.radio-inline label {
  cursor: pointer;
}

.radio-inline input:checked + label {
  font-weight: bold;
  color: #4CAF50;
}

@media (max-width: 768px) {
  .payment-bar {
    width: 90%;
  }

  .radio-inline {
    font-size: 26px;
    margin: 0 10px; 
  }
}
