.overalls {
  display: flex;
  flex-direction: column;
  width: 95vw;
  height: 90vh;
  overflow-x: hidden;
  padding-left: 2px;
}
.dashboard1 {
  display: flex;
  align-items: center;
}
.totalorderview {
  display: flex;
  width: auto;
  justify-content: space-between;
}
.totalview {
  width: 600px;
  height: 400px;
  overflow-y: auto;
}
.Barchart {
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.change_switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
}

.chart-container {
  width: 100%;
  height: 300px;
  width: 435px;
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chart-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.chart-description {
  font-size: 13px;
  color: #777;
  margin-top: 15px;
  text-align: center;
}

.dashboard-containers {
  display: flex;
  gap: 20px;
  padding: 5px;
}

.barchart-container {
  width: 600px;
}

.chart-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
}

.chart-toggle h4 {
  margin: 0;
}

.table-container {
  width: 800px;
}

.year-selector {
  display: flex;
  align-items: center;
}

.year-selector button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0 10px;
}

.year-selector button:hover {
  color: #007bff;
}

.dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 20px;
}

