.header_bar {
    width: 150vw;
    height: auto;
}

.header_menu {
    background-color: #f2f7f7;
    width: 50%;
    display: flex;
    margin-left: 70px;
    height: 60px;
    border-radius: 10px;
    justify-content: space-between;
}

.header_menu ul {
    display: flex;
    justify-content: space-around;
    font-size: 20px;
    align-items: center;
    width: 100%;
}

.header_menu ul li {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.head_of_category {
    width: 100%;
    height: auto;
}


.button-container {
    display: flex;
    width: 60%;
    margin-left: 20px;
    justify-content: flex-end;
}

.Res-modals,
.Res-overlays {
  width: auto;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow: scroll;
}

.Res-overlays {
  background: rgba(49, 49, 49, 0.8);
}

.Res-modal-contents {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 10px;
  max-width: 600px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

@media screen and (max-width: 125%) {
  .Res-modal-contents {
    top: 10vh;
  }
}

@media screen and (min-width: 125%) {
  .Res-modal-contents {
    top: 0;
    transform: translate(-50%, 0);
  }
}

.Res-table-header {
    font-weight: bold;
    z-index: 0;
  }

.body-of-table{
    height: auto;
    width: 55%;
    margin-left: 90px;
}

.table-header-restarunt{
    font-weight: bold;
    /* background-color: #f5f5f5; */
    background-color: gray;
    z-index: 0;
}
.catogory-table{
    width: 100%;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 480px;
}

.addcatpgory-button{
    width: 59%;
    margin-top: 30px;
    display: flex;
    flex-direction: row-reverse;
}

.adduser-button{
    width: 85%;
    margin-top: 30px;
    display: flex;
    flex-direction: row-reverse;
}

.add-category-heading{
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-container {
    display: flex;
    justify-content: flex-end; 
    gap: 10px; 
    margin-top: 20px; 
}

.button-container .MuiButton-root {
    padding: 8px 16px; 
    font-size: 14px; 
    border-radius: 8px; 
    text-transform: none; 
    transition: background-color 0.3s ease, box-shadow 0.3s ease; 
}

.button-container .MuiButton-root:disabled {
    background-color: #ccc; 
    color: #666; 
    cursor: not-allowed; 
}

.button-container .MuiButton-containedPrimary {
    background-color: #1976d2; 
    color: #fff;
}

.button-container .MuiButton-containedPrimary:hover {
    background-color: #155a9d; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.button-container .MuiButton-text {
    color: #555;
    background-color: transparent;
    border: 1px solid #ccc; 
}

.button-container .MuiButton-text:hover {
    color: #000;
    border-color: #999; 
    background-color: #f5f5f5; 
}

.edit-category-restarunt{
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-catagory-heading{
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-container-popup {
    display: flex; 
    gap: 30px; 
    justify-content: center; 
    align-items: center; 
}

.cancel-btn {
    padding: 10px 20px;
    background-color: #f44336; 
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.submit-btn {
    padding: 10px 20px;
    background-color: #4CAF50; 
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.cancel-btn:hover {
    background-color: #d32f2f; 
}

.submit-btn:hover {
    background-color: #388e3c; 
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .dialogPaper {
    padding: 20px;
    border-radius: 12px;
    max-width: 600px;
    background-color: #fff;
  }
  
  .user-dialogtitle {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #333;
    padding-bottom: 10px;
  }
  
  .user-textfield {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    width: 100%;
    padding: 10px 0;
  }
  
  .user-textfield .MuiTextField-root {
    width: 100%;
  }
  
  .dialog-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .dialog-buttons .MuiButton-root {
    font-size: 16px;
    padding: 10px 24px;
    text-transform: none;
    border-radius: 8px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
  }
  
  .confirm-button {
    background-color: #007bff;
    color: white;
  }
  
  .confirm-button:hover {
    background-color: #0056b3;
  }
  
  .cancel-button {
    background-color: #ff4d4d;
    color: white;
  }
  
  .cancel-button:hover {
    background-color: #cc0000;
  }

  .usertable{
    width: 95vw;
    display: flex;
    justify-content: center;
    align-items: start;
    height: auto;
    overflow: auto;
  }
  
  .addcustomer-btn{
    width: 59%;
    margin-top: 30px;
    display: flex;
    flex-direction: row-reverse;
  }

  .search-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px; 
    padding: 10px;
    width: 59%;
  }
  
  .search-input {
    width: 100%;
    max-width: 400px; 
    background-color: white;
    border-radius: 8px;
  }
  
  .search-input .MuiOutlinedInput-root {
    border-color: #ccc; 
  }
  
  .search-input .MuiOutlinedInput-root:hover fieldset {
    border-color: #007bff; 
  }
  
  .search-input .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #007bff; 
  }
  
  @media (max-width: 600px) {
    .search-container {
      justify-content: center;
    }
  }

  .user-textfield-customer {
    padding: 20px;
  }
  
  .form-field {
    margin-bottom: 20px;
  }
  
  .dialog-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .dialog-actions button {
    margin-left: 10px;
  }

.edit-dialog .MuiDialog-paper {
  padding: 20px;
  border-radius: 12px;
  width: 400px;
}

.edit-dialog-title {
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  color: #333;
}

.edit-dialog-content {
  display: flex;
  flex-direction: column;
}

.edit-input .MuiOutlinedInput-root {
  border-radius: 8px;
  margin-bottom: 16px;
}

.edit-dialog-actions {
  margin-top: 20px;
  text-align: right;
}

.cancel-btn {
  text-transform: none;
  font-weight: bold;
  color:white;
}

.confirm-btn {
  text-transform: none;
  font-weight: bold;
  background-color: #1976d2;
  margin-left: 10px;
}

.confirm-btn:hover {
  background-color: #1565c0;
}

.center-text{
  text-align: center;
}  
.view-deleted{
  position: relative;
}
.popup {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

@media screen and (max-width: 125%) {
  .popup {
    top: 10vh;
  }
}

@media screen and (min-width: 125%) {
  .popup{
    top: 0;
    transform: translate(-50%, 0);
  }
}

.delete-product {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 200px;
  justify-content: center;
  align-items: center;
}

.btn-cancel {
  display: flex;
  width: 400px;
  justify-content: space-evenly;
  margin-top: 20px;
}

.resconfig-delete {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 200px;
  justify-content: center;
  align-items: center;
}

.resconfig-cancel {
  display: flex;
  width: 400px;
  justify-content: space-evenly;
  margin-top: 20px;
}
