* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.container {
  display: flex;
  position: fixed;
  height: 100%;
}

.menu-bar {
  display: flex;
  flex-direction: column;
  width: 5vw;
  padding-left: 1vw;
}

.menu-icon {
  color: var(--color);
}

.menu-icon:hover {
  transform: scale(1.5);
  cursor: pointer;
}

.container-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  width: 70px;
  height: 700px;
  border-top-right-radius: 35px;
  background-color: rgb(236, 236, 236);
}

.manuhidden-container {
  background-color: #1678f3;
  width: 3.5vw;
  height: 100vh;
}

.menu_overview {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 25px;
  justify-content: center;
  align-items: center;
  background-color: #1678f3;
}

.menu_overviews {
  width: 105px;
  height: 100vh;
  display: flex;
}

.menu_hiddenicons {
  display: flex;
  flex-direction: column;
  width: 80px;
  margin-top: 5vh;
  padding: 5px;
}

.menu_back {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1678f3;
}

.menu_selected {
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.menu_selecteds {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  width: 115px;
  height: 65px;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  transition: transform 0.3s;
}

.menu_selecteds p {
  color: var(--color);
  width: 110px;
  font-size: small;
  font-weight: bolder;
  font-family: var(--fontfamily);
}

.menu_selecteds:hover {
  width: 115px;
  overflow: hidden;
  transform: scale(1.2);
}

.menu-icons {
  color: var(--color);
  margin-left: 3.9px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  border-radius: 35px;
  border: 2px solid var(--background-color);
}

.menu-expanded {
  width: 80px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.menu-collapsed {
  width: 60px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.menubaricon {
  z-index: 1001; 
  position: fixed;  
  top: 10px;       
  left: 40px;     
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (min-height: 700px) {
  .menu-container {
    display: flex;
    flex-direction: column;
    transition: width 0.3s, border-radius 0.2s;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }

  .menubaricon {
    display: none;
  }
}

@media (max-height: 700px) {
  .menu-container {
    display: none;
  }

  .menubaricon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

@media (max-width: 768px) {
  .menu-container {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 1024px) {
  .menu-container {
    width: 100%;
    height: 100%;
  }
}

.menuicons {
  z-index: 1000;
  bottom: 10px;
  margin-left: 5vw;
  right: 50px;
  justify-content: flex-start;
  align-items: flex-end;
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.overlay-content {
  /* z-index: 1000; */
  background: #e1e1e1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-left: 5vw;
  top: 10px;
  box-sizing: border-box;
  position: relative;
  height: 600px;
  margin-right: 30px;
  width: 130px;
  margin-top: 40px;
}

.menuitem {
  margin: 10px 0;
  font-size: 15px;
  cursor: pointer;
}
