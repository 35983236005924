.billover {
  width: 600px;
  height: 800px;
  border: 1px solid black;
}
.hedder {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 599px;
  height: 40px;
  font-size: x-large;
  border-bottom: 1px solid black;
}
.Details_overall {
  width: 600px;
  height: 200px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 0.5px;
  padding: 1px;
  grid: 95px / auto auto;
}
.detail_color {
  border: 1px solid black;
  padding: 1px;
}
.body_of_bill {
  width: 590px;
  margin-left: 5px;
  height: 555px;
  background-color: bisque;
}
.scroll-down-arrow {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  color: #333;
  cursor: pointer;
  z-index: 999;
}

.scroll-down-arrow:hover {
  color: #007bff;
}
.logo-container{
  border-radius: 60px;
  width: 60px;
  height: 60px;
}