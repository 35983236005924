.profileall {
  width: 1500px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  /* background-color: burlywood; */
}

.Profile_logo_page {
  display: flex;
  overflow: hidden;
  margin-left: 20px;
  width: 1000px;
  /* background-color: bisque; */
}

.logo_display {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  border-radius: 200px;
}

.logo_display li {
  background-color: red;
}

.logodata {
  width: 200px;
  height: 200px;
  border: solid 1px black;
  border-radius: 200px;
}

.logo_change_button_icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  margin-right: -5px;
}

.logoupload {
  margin-left: -58px;
  margin-bottom: -150px;
}

/* start */
.Profile_menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10vw;
  width: 1200px;
  height: 40px;
}

.page_setting {
  max-width: 1500px;
  min-width: 800px;
  display: flex;
  align-items: center;
  width: 90vw;
  height: 85vh;
  overflow: auto;
  /* background-color: aquamarine; */
}

/* .inside_content{
 background-color: blue;
} */

.profile_menu {
  max-width: 260px;
  min-width: 130px;
  height: 80vh;
  border-radius: 20px;
}

/* .profile_menu:hover{
 background-color: greenyellow;
} */

.profile_display {
  width: 1400px;
  height: 80vh;
}

.ovetall_profile {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  width: 96vw;
  min-width: 800px;
  height: 92vh;
  overflow: auto;
}

.profile_menu ul {
  padding: 10px 0px 0px 3px;
  height: 80vh;
  border: solid 1px rgba(139, 135, 135, 0.6);
  border-radius: 0px 20px 20px 0px;
}

.profile_menu li {
  margin-top: 15px;
  cursor: pointer;
}

.onclick_Style {
  background-color: var(--background-color);
  color: white;
  padding-left: 2px;
  font-weight: bold;
  border-radius: 4px 0px 0px 4px;
}

.Profile_logo_page ul {
  max-width: 1200px;
  min-width: 500px;
  height: 60vh;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  /* background-color: chocolate; */
}

.Profile_logo_page li {
  margin-top: 15px;
  display: flex;
  max-width: 600px;
  min-width: 500px;
  justify-content: space-between;
  /* background-color: aquamarine; */
}

.Profile_logo_page p {
  max-width: 30vw;
  min-width: 250px;
  font-size: larger;
  font-weight: bold;
}

.My_Profile {
  display: flex;
}

.My_Profile ul {
  max-width: 1000px;
  min-width: 500px;
  height: 60vh;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  /* background-color: black; */
}

.My_Profile li {
  margin-top: 15px;
  display: flex;
  max-width: 60vw;
  min-width: 500px;
  justify-content: space-between;
  /* background-color: blue; */
}

.My_Profile p {
  max-width: 30vw;
  min-width: 250px;
  font-size: larger;
  font-weight: bold;
}

.Theme_selection {
  display: flex;
  /* background-color: aqua; */
}

.Theme_selection ul {
  margin-left: 10px;
  width: 60vw;
  max-width: 500px;
  min-width: 400px;
  min-height: 200px;
  height: 30vh;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  border: solid 1px rgba(139, 135, 135, 0.6);
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  overflow: hidden;
  border-radius: 20px;
  /* background-color: bisque; */
}

.Theme_selection h3 {
  margin-left: 10px;
  width: 600px;
  max-width: 400px;
  /* background-color: aqua; */
}

.Theme_selection body {
  display: grid;
}

.Theme_selection li {
  margin: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 10px;
}

.Theme_selection p {
  margin-top: 40px;
  margin-left: -10px;
  width: 60px;
  display: flex;
  font-size: small;
  justify-content: center;
}

.Theme_selection h5 {
  margin-left: -10px;
  margin-top: -5px;
  width: 60px;
  display: flex;
  font-size: small;
  justify-content: center;
  /* background-color: chartreuse; */
}

.Theme_selection li:hover {
  transform: scale(1.05);
}

.theme_trail {
  margin-left: 10px;
  margin-top: 10px;
  width: 500px;
  height: 300px;
  border: 5px solid rgb(0, 0, 0);
  border-radius: 5px;
  overflow: hidden;
  font-family: var(--font-family);
}

.theme_trail header {
  background-color: var(--backgroundcolor);
  display: flex;
  justify-content: space-between;
  width: 471px;
  margin-left: 20px;
  padding-left: 10px;
  /* position: static; */
}

.theme_trail p {
  display: flex;
  color: var(--font-color);
  width: 130px;
  margin-left: 10px;
}

.theme_trail h4 {
  display: flex;
  width: 120px;
  align-items: center;
  background-color: #e1e1e1a0;
  border-radius: 10px;
  margin-right: 2px;
  height: 20px;
  margin-top: 2px;
}

.menu_trail {
  width: 30px;
  background-color: #e1e1e1;
  height: 291px;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  flex-direction: column;
}

.menu_trail img {
  width: 20px;
  height: 20px;
  border: solid 1px black;
  border-radius: 200px;
}

.menu_trail ul {
  display: flex;
  flex-direction: column;
  width: 30px;
  height: 291px;
  align-items: center;
  margin-top: 4px;
}

.menu_trail li {
  margin-top: 20px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}

.body_page_trail {
  display: flex;
  margin-top: -23.9px;
}

.bodytheme {
  margin: 20px 0px 0px 30px;
  width: 456px;
  height: 280px;
  display: flex;
  margin-left: -1px;
  justify-content: center;
  align-items: center;
  background-color: rgba(184, 156, 156, 0.188);
  font-family: var(--font-family);
  ;
}

.bodytheme ul {
  width: 300px;
  height: 150px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bodytheme li {
  margin-top: 5px;
  font-weight: bold;
}

.cancelbtn {
  background: none;
  padding: 2px 5px 2px 5px;
  border-radius: 20px;
  margin: 5px;
  border: 2px solid var(--backgroundcolor);
  color: var(--backgroundcolor);
  font-family: var(--font-family);
}

.confirmbtn {
  background-color: var(--backgroundcolor);
  padding: 2px 5px 2px 5px;
  border-radius: 20px;
  margin: 5px;
  border: 2px solid var(--backgroundcolor);
  color: var(--font-color);
  font-family: var(--font-family);
}

.select_font_type {
  width: 30px;
  height: 20px;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.Update_Theme {
  width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.profile_heading {
  /* background-color: red;
  width: 150%;
  margin-left: 50px;
  display: flex; */
  /* margin-left: 50px;
  padding-left: 10px;
  width: 300%;
  height: 420px;
  display: flex;
  background-color: rgb(206, 244, 244);
  border: 1px solid black; */
  border-radius: 10px;
  width: 150%;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: rgb(206, 244, 244);
  margin-left: 60px;
}

.profile_heading li {
  margin-left: 30px;
}

.profile_heading p {
  width: 20%;
}

.my_profile {
  width: 500px;
  height: 300px;
  display: flex;
}

.Edit_profile {
  width: 30%;
  height: 10%;
  font-size: larger;
  color: green;
  margin-left: 250px;
  border-radius: 15px;
  font-style: oblique;
  position: relative;
  bottom: 20px;
  cursor: pointer;
}

.inside_content li:hover {
  border: 1px solid black;
  padding: 2px;
  border-radius: 5px;
}

.Profile_edit_page {
  width: 25%;
  height: 130%;
  font-size: larger;
  color: green;
  margin-left: 250px;
  border-radius: 15px;
  cursor: pointer;
}

.Edit_business_overall {
  justify-content: space-between;
}

.business_btn {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  width: 100%;
}

.Edit_head {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_profile {
  background-color: rgb(206, 244, 244);
  width: 70%;
  height: 300px;
  margin-left: 30px;
  border: 1px solid black;
  border-radius: 25px;
  padding: 5px;
}

.Profile_edit_page_user {
  width: 25%;
  height: 130%;
  font-size: larger;
  color: green;
  margin-left: 150px;
  border-radius: 25px;
  font-style: oblique;
  cursor: pointer;
  position: relative;
  top: 8px;
  right: 10px;
}

.user_profile {
  max-width: 600px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: rgb(206, 244, 244);
}

ul {
  list-style-type: none;
  padding: 0;
}

.profile_item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  margin-right: 10px;
}

.label {
  flex: 1;
  font-weight: bold;
}

.value {
  flex: 2;
  text-align: left;
}

.button_container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.cancel-icon-Editbusiness {
  background: none;
  border: none;
  position: relative;
  left: 540px;
  bottom: 45px;
}

.cancel-icon-Edituser-profile {
  background: none;
  border: none;
  position: relative;
  left: 540px;
  bottom: 45px;
}

.data_button {
  height: auto;
  width: 90%;
}

.import_heading {
  margin-left: 10px;
}

.import_button_profile {
  margin-left: 150px;
  margin-top: 100px;
  height: 3.5rem;
  width: 27%;
  border-radius: 10px;
  font-size: larger;
  box-shadow: 10px 15px lightblue;
  cursor: pointer;
}

.download_icon {
  background-color: green;
  width: 8px;
  height: 5rem;
}
.lable_export {
  font-size: large;
  position: relative;
  top: 5px;
}
.select_export_profile {
  width: 50%;
  cursor: pointer;
  border-radius: 5px;
  font-size: larger;
  border: 2px solid black;
}

/* .Profile_edit_page_user {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 40px;
}

.Profile_edit_page_user:hover {
  background-color: #0056b3;
} */


/* inside_content li{
  background-color: blueviolet;
} */