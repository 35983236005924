.carmenumain-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #ddd;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    height: 89vh;
    width: 65px;
    box-sizing: border-box;
  }
  
  .carmenu-list {
    width: 100%;
    flex: 1;
    padding: 10px;
    margin: 0;
  }
  
  /* .carmenu-list {
    padding: 38px ;
    border-radius: 40px;
    width: 30px;
    display: flex;
    justify-content: center;
  }
   */
  .carmenu-item {
    /* padding: 10px ; */
    border-radius: 40px;
    width: 10px;
    margin: 35px 0px 0px 0px;
    justify-content: center;
  }
  
  .carmenu-list .carmenu-item:hover {
    transform: scale(1.05);
  }
  
  .logout-section {
    margin-top: auto;
    text-align: center;
    width: 100%;
  }
  
  .logout-button {
    color: #d32f2f;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .logout-button:hover {
    background-color: #f8d7da;
    transform: scale(1.05);
    box-shadow: 0px 4px 10px rgba(211, 47, 47, 0.2);
  }
