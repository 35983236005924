.header-profile {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-image: url("../../../assets/Images/car-wash-background.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.profile-first {
  background-color: white;
  border-radius: 15px;
  text-align: center;
  align-items: center;
  font-size: 18px;
  width: 100%;
  min-width: 480px;
  max-width: 70%;
  height: 100%;
  padding: 30px;
}

.custom-hr {
  color: black;
  margin-bottom: 20px;
  margin-top: -10px;
}

.profile-details {
  display: grid;
  margin-top: -20px;
}

.profile-details .profile-item {
  display: grid;
  margin-bottom: -18px;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  margin-right: 10px;
  align-items: center;
  grid-template-columns: 1fr 2fr;
}

.profile-details .profile-item .label {
  font-weight: normal;
  font-size: 20px;
  text-align: left;
  width: 100%;
  margin-top: 20px;
  margin-left: 70%;
}

.profile-details .profile-item .value {
  font-weight: normal;
  font-size: 20px;
  width: 50%;
  margin-left: 20%;
  margin-bottom: -17px;
}

.profile-details .profile-item .input-box {
  margin-top: 5px;
  width: 50%;
  font-size: 16px;
  border-radius: 4px;
  margin-left: 170px;
}

.profile-details .profile-item .value::before {
  content: ":";
  margin-left: 10px;
  margin-right: 5px;
}

.profile-details .profile-item .input-box::before {
  content: ":";
  margin-left: -19px;
  margin-bottom: -30px;
  margin-top: 1px;
  width: 10px;
}

@media screen and (max-width: 768px) {
  .profile-first {
    width: 85%;
    height: 100;
    max-width: 85%;
    padding: 20px;
  }

  .profile-details .profile-item {
    margin-bottom: 10px;
    width: 90%;
    margin-top: 20px;
    grid-template-columns: 1fr 2fr;
    align-items: flex-start;
    margin-left: 80px;
  }

  .profile-details .profile-item .label {
    font-size: 18px;
    margin-top: 5px;
    margin-left: 0;
    width: auto;
    text-align: left;
  }

  .profile-details .profile-item .value {
    font-size: 18px;
    margin-left: 0;
    width: auto;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .profile-details .profile-item .value::before {
    content: ":";
    margin-left: 5px;
  }
  .profile-details .profile-item .input-box {
    margin-top: 5px;
    width: 80%;
    font-size: 16px;
    border-radius: 4px;
    margin-left: 20px;
  }
}

.logo-containers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  margin-top: -20px;
}

.logo {
  width: 100px;
  height: 100px;
  border: solid 1px black;
  border-radius: 50%;
  position: relative;
}

.logo-upload {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_change_button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  margin-bottom: -60px;
  margin-left: -30px;
}

.logo-display {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-edit-display {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -25px;
}

@media screen and (max-width: 768px) {
  .logo {
    width: 80px;
    height: 80px;
    top:5px;
  }

  .logo-upload {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .logo-change-button {
    width: 45px;
    height: 45px;
  }
}

.closeicon {
  margin-right: -100%;
  margin-top: -35px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px), (max-width: 600px){
  .custom-dialog-actions {
    margin-left: 80px;
    align-items: center;
    display: flex;
  }

  .custom-dialog-actions button {
    margin-top: -10px;
    width:30%;
  }
}
