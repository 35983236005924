.Popup {
  width: 450px;
  height: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Popup-late {
  width: 450px;
  height: 550px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Popup_bill {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -30px;
  height: 260px;
}

.container1 {
  display: flex;
  flex-direction: row;
  width: 400px;
  justify-content: space-between;
  margin: 4px;
  background-color: lightgray;
}

.container3 {
  display: flex;
  flex-direction: row;
  width: 400px;
  justify-content: space-between;
  margin: 4px;
  background-color: lightgray;
}

.container2 {
  width: 400px;
  height: 400px;
  margin: 4px;
  overflow-y: scroll;
}

.display {
  font-size: large;
  width: 12vh;
  display: flex;
  align-items: center;
}

.display2 {
  font-size: large;
  width: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  background-color: rgb(37, 37, 151);
  color: aliceblue;
  width: 170px;
  height: 45px;
  font-size: x-large;
  border-radius: 40px;
  font-size: large;
}

.customersearch {
  /* display: flex; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.display_name {
  width: 95%;
  padding: 5px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
}

.customer:hover {
  background-color: rgb(252, 247, 247);
  border: 1px solid rgba(93, 92, 92);
  border-radius: 10px;
  padding: 2px;
  font-weight: bold;
  height: 40px;
}

.inputbox {
  display: flex;
  font-size: 25px;
  width: 520px;
  height: 40px;
  justify-content: flex-start;
  border: 0.5px solid black;
  background: none;
  border-radius: 40px;
  margin-left: -40px;
  padding-left: 50px;
  padding-right: 10px;
}

.customerdeatiles {
  display: flex;
  margin-top: 8px;
  width: 520px;
  height: 370px;
  padding-right: 20px;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
}

.customer {
  display: flex;
  margin: 1px;
  width: 480px;
  height: 40px;
  font-weight: 100;
  justify-content: space-between;
  cursor: default;
}

.deatil_name {
  font-size: 18px;
  width: 40%;
  margin-left: 10px;

}

.deatil_ph {
  display: flex;
  font-size: 18px;
  width: 40%;
  flex-direction: row-reverse;
}

.Aname {
  display: flex;
  width: 150px;
  margin-left: 20px;
  padding: 10px;
  font-size: 1.2rem;
}

.Ainput {
  display: flex;
  width: 400px;
  height: 40px;
  font-size: x-large;
  margin-right: 20px;
  margin-top: 10px;
  border: 1px solid black;
  background-color: #f0ebeb;
  border-radius: 4px;
}

.Aaddress {
  display: flex;
  flex-direction: column;
}

.Aname2 {
  display: flex;
  width: 400px;
  height: 45px;
  margin-left: 20px;
  font-size: 1.2rem;
  border-top: 40px;
  position: relative;
  top: 6px;
}

.Ainput2 {
  display: flex;
  width: 540px;
  height: 43px;
  font-size: x-large;
  margin-left: 20px;
  position: relative;
  right: 30px;
  background-color: #f0ebeb;
}

.Abtn {
  border: none;
  margin-top: 10px;
  font-size: large;
  width: 180px;
  height: 40px;
  border-radius: 20px;
  position: relative;
  bottom: 7px;
  left: 90px;
  top: 1px;
}

.Abtn1 {
  background-color: #035bc9;
  color: black;
  font-size: large;
  color: white;
  width: 265px;
  height: 40px;
  margin-left: 20px;
  border-radius: 20px;
  position: relative;
  left: 90px;
  top: 10px;
}

.Abtn2 {
  background-color: #035bc9;
  font-size: large;
  color: white;
  width: 140px;
  height: 45px;
  margin-left: 19px;
  border-radius: 40px;
}

.paymentmode {
  width: 170px;
  height: 170px;
  margin-bottom: 20px;
}

.payview {
  display: flex;
  width: 400px;
  height: 300px;
  justify-content: space-between;
  align-items: center;
}

.paymentview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Product_data_save {
  display: flex;
  justify-content: space-around;
  width: 500px;
  margin-top: 10px;
}

.paymentview1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 565px;
  margin-left: 10px;
}

.psview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.psinput {
  width: 80px;
  height: 40px;
  font-size: large;
  background: none;
  border-radius: 10px;
}

.Btndone {
  width: 160px;
  height: 50px;
  background-color: #035bc9;
  color: white;
  font-size: large;
  margin-top: -40px;
  border-radius: 30px;
  margin-left: 5px;
}

.error {
  color: #df1d16;
  font-size: larger;
}

.customerdetails {
  display: flex;
  width: 500px;
  align-items: center;
  justify-content: space-between;
  margin: 2px;
}

.customerdet {
  width: 210px;
  height: 100px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: -50px;
}

.display1 {
  display: flex;
  justify-content: space-between;
}

.containers1 {
  margin-top: -20px;
  width: 100px;
}

.containers2 {
  width: 100px;
}

.containers3 {
  margin-top: -20px;
  margin-left: 20px;
}

.popups {
  width: 800px;
  height: 700px;
  display: flex;
}

.print-bill {
  font-family: Arial, sans-serif;
  padding: 20px;
  width: 450px;
  height: 700px;
  overflow-x: auto;
  zoom: -20;
}

@media print {
  .print-bill {
    background-color: white;
    border: 1px solid black;
    margin: 0 auto;
    width: 50%;
    padding: 20px;
  }
}

.customerscreen {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.back-Arrow {
  width: 420px;
  margin-top: 20px;
  margin-bottom: -20px;
}

.bom_back_arrow {
  display: grid;
  justify-content: space-between;
  align-items: center;
  margin-left: 32px;
}

.Abtn:hover {
  background-color: white;

  border: 1px solid black;
}

.Abtn1:hover {
  background-color: #03c914;
}

.details {
  position: relative;
  align-content: center;
  align-items: center;
  width: 100%;
  font-size: 30px;
  display: flex;
  justify-content: center;
}

.searchin {
  position: relative;
  top: 9px;
  padding-left: 20px;
  position: relative;
  margin-right: -80px;
}

.aero {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.cancel-icon {
  position: relative;
  left: 20px;
  bottom: 15px;
  background: none;
  border: none;
  cursor: pointer;
}

.cancel-icon-product {
  position: relative;
  left: 405px;
  bottom: 30px;
  background: none;
  border: none;
  cursor: pointer;
}

.cancel-icon-paymethod {
  position: relative;
  left: 255px;
  bottom: 8px;
  background: none;
  border: none;
  cursor: pointer;
}

.cancel-icon-paylate {
  position: relative;
  left: 255px;
  bottom: 8px;
  background: none;
  border: none;
  cursor: pointer;
}

.cancel-icon-done {
  position: relative;
  left: 540px;
  bottom: 6px;
  background: none;
  border: none;
  cursor: pointer;
}

.cancel-icon-add-custom {
  position: absolute;
  bottom: 10px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  transform: translateY(-50%);
  right: -25px;
}

.cancel-icon-ledger {
  position: absolute;
  bottom: 10px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  transform: translateY(-50%);
  right: 20px;
  margin-bottom: 220px;
}

.arrowback-ledger {
  position: absolute;
  bottom: 10px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  transform: translateY(-50%);
  margin-bottom: 220px;
  margin-right: 10px;
  margin-left: -40px;
}

.cancel-icon-payment {
  position: relative;
  left: 80px;
  bottom: 50px;
  background: none;
  border: none;
  cursor: pointer;
}

.power {
  margin-left: 13px;
  margin-bottom: 10px;
}

.atom {
  background: none;
  border: none;
}

.proplay {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
}

.order-phno {
  display: flex;
  font-size: medium;
  width: 160px;
  /* justify-content: center;
  align-items: center; */
  margin-left: 10px;
}

.order-customer {
  display: flex;
  font-size: medium;
  width: 200px;
  /* justify-content: center;
  align-items: center; */
  flex-direction: row-reverse;
}

.Table_product {
  display: flex;
  justify-content: center;
  width: 500px;
  height: 400px;
  overflow-x: auto;
}

.cool {
  position: relative;
  left: -61px;
}

.Bond {
  color: rgb(17, 16, 16);
  font-size: large;
  width: 120px;
  height: 43px;
  border-radius: 20px;
  margin-top: 3px;
}

.back-Arrow2 {
  width: 50px;
  /* margin-right: 370px; */
  position: relative;
  bottom: 5px;
  right: 230px;
}

.back-Arrow2-paycash {
  width: 50px;
  margin-right: 370px;
  position: relative;
  top: 20px;
}

.paymentmode:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.delivery-date {
  display: flex;
  margin-right: 80px;
}

.print-button {
  background-color: rgb(238, 231, 231);
  font-size: large;
  bottom: 80px;
  border-radius: 30px;
  height: 40px;
  width: 140px;
  margin-top: 30px;
  cursor: pointer;
}

.search-on {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.edit-popupscreens {
  width: 1500px;
  height: 200px;
}

.confirm_btn_edit {
  width: 300px;
  margin-top: 20px;
  margin-left: -10px;
  display: flex;
  justify-content: space-around;
}

.Edit_customer_data {
  width: 500px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.print-button:hover {
  background-color: white;
}

.note-style {
  width: 554px;
  margin-left: 16px;
  background-color: #8a8e8b;
  color: black;
  padding: 2px;
  margin-left: -30px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 10px;
}

.input-name {
  margin-right: 60%;
  margin-top: 10px;
}

.input-phone {
  margin-top: 10px;
}

.input-Email {
  margin-top: 10px;
  display: block
}

.input-address {
  margin-top: 15px;
}

.input-address ul {
  border: solid 1px #a09d9dd3;
  padding: 8px;
  display: grid;
  border-radius: 5px;
  width: 95%;
}

.modals,
.overlays {
  width: auto;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.modals.show {
  display: block;
}

.input_emil_text {
  background-color: rgb(222, 222, 220);
  width: 102%;
  color: black;
  position: relative;
  top: 10px;
}

.modal-footer {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.modal-contents {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

.customerledger {
  display: flex;
  width: 450px;
  height: 250px;
  /* background: #035bc9; */
}

.customerledger .label {
  font-weight: normal;
  font-style: normal;
  font-size: inherit;
  line-height: 2.9;
}

.customerledger li {
  display: flex;
  width: auto;
  height: auto;
  margin: 0px 30px 10px 0px;
}

.customerledger p {
  width: 210px !important;
  font-size: x-large;
}

.customerledger li div {
  margin: 0px 10px 20px 0px;
  ;
}

.customer_error {
  color: black;

}

.modals,
.overlays {
  width: auto;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlays {
  background: rgba(49, 49, 49, 0.8);
}

.modal-contents {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 30vh;
}

.error {
  color: #df1d16;
  font-size: larger;
}

.backhome-btn {
  position: absolute;
  top: 5px;
  left: 230px;
  cursor: pointer;
  border: none;
}

.add_item {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.cancel-icon {
  display: flex;
  align-items: end;
  justify-content: end;
  position: relative;
  left: 170px;
}

.paycash-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

.paycash-details {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #035bc9;
  margin-right: 30px;
}

.paycash-values {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #035bc9;
}

.paycash-info p {
  margin: 0;
}

.paylate-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

.paylate-details {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #035bc9;
  margin-right: 30px;
}

.paylate-values {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #035bc9;
}

.paylate-info p {
  margin: 0;
}