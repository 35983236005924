.reservation_head{
  width: 100%;
  height: auto; 
}
/* .container {
    display: flex;
    flex-direction: column; 
  } */
  
.reservation_cart{
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: end;
    align-items: end;
    margin-left: -95px;
}

.Table_header{
    width: 95vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    overflow: auto;
    margin-top: 20px;
}