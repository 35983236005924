.Container1 {
  display: flex;
  flex-direction: column;
  width: 1712px;
  height: 1174px;
}

.colarcontainer-1 {
  background-color: #1678f3;
  width: 2vw;
  height: 100vh;
}

.Header {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 60px;
}

.com-header {
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #ffffff;
}

.inside_element{
  display: flex;
  justify-content: space-between;
  padding: 5px;
  z-index: 1000;
}

.inside_element:hover{
  /* background-color: rgb(252, 247, 247); */
  background-color: rgb(244, 250, 233);
  border: 1px solid rgba(93, 92, 92);
  border-radius: 10px;
}

/* .Container2 {
  display: flex;
  flex-direction: row;
} */
.cointainer_statement{
  width: 30vw;
}

.order {
  display: flex;
  width: fit-content;
  width: 50%;
  height: 100vh;
}

.Container3 {
  display: flex;
  flex-direction: column;
  width: 200vh;
}

.Order {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.signall {
  width: 98vw;
  height: 100vh;
  display: flex;
  margin: 2px;
}

.overview {
  width: 600px;
  position: relative;
  height: 620px;
  margin-bottom: 20px;
}

.Container1 {
  display: flex;
  flex-direction: column;
  width: 60vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.Colorcontainer {
  background-color: #1678f3;
  width: 40vw;
  height: 100vh;
  border-top-left-radius: 79px;
  border-bottom-left-radius: 79px;
}

.inputuser {
  width: 590px;
  height: 47px;
  border-radius: 13px;
  padding-left: 40px;
  margin-left: -35px;
  background: none;
}

.imgs {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.inbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
}

.inputusers {
  width: 289px;
  height: 47px;
  border-radius: 13px;
  padding-left: 40px;
  margin-left: -35px;
  background: none;
  margin-left: -30px;
  margin-top: -10px;
}

.inbox1 {
  display: flex;
  flex-direction: column;
  margin: 2px;
}

.signbtns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -10px -10px -10px 0px;
  width: 600px;
}

.btns1 {
  background-color: #1678f3;
  border-radius: 23.5px;
  width: 210px;
  height: 47px;
  color: aliceblue;
  font-size: x-large;
}

.btnsdisabled {
  background-color: #167af378;
  border-radius: 23.5px;
  width: 210px;
  height: 47px;
  color: aliceblue;
  font-size: x-large;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  height: 30px;
  width: 94vw;
  font-size: large;
}

.head-name {
  display: flex;
  height: 60px;
  width: 100vw;
  justify-content: space-between;
  padding-left: 100px;
  align-items: center;
  background-color: var(--background-color);
  font-family: var(--fontfamily);
}

.heder-profile-laundry {
  display: flex;
  align-items: center;
  /* width: 250px;
  height: 40px; */
  background-color: #e1e1e1a0;
  /* background-color: red; */
  /* margin-left: 70px; */
  border-radius: 40px;
}

.heder_profiles {
  display: flex;
  width: 250px;
  height: 40px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  margin-top: 180px;
  margin-left: 4px;
  position: absolute;
  background-color: #e0e0e0;
  width: 242px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 20px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-contents {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 12px;
  border-radius: 20px;
  align-items: center;
  height: auto;
  position: absolute;
  background-color: #e4ead0;
  width: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 5000;
}

.dropdown-contents a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-contents a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-contents {
  display: block;
}

.color1 {
  background-color: #1678f3;
  width: 50px;
  height: 50px;
  margin: 10px;
  border-radius: 50px;
}

.color2 {
  background-color: #A93226;
  width: 50px;
  height: 50px;
  margin: 10px;
  border-radius: 50px;
}

.color3 {
  background-color: #BD7E27;
  width: 50px;
  height: 50px;
  margin: 10px;
  border-radius: 50px;
}

.color4 {
  background-color: #2471A3;
  width: 50px;
  height: 50px;
  margin: 10px;
  border-radius: 50px;
}

.color5 {
  background-color: #7a2344;
  width: 50px;
  height: 50px;
  margin: 10px;
  border-radius: 50px;
}

.color6 {
  background-color: #209753;
  width: 50px;
  height: 50px;
  margin: 10px;
  border-radius: 50px;
}

.color7 {
  background-color: #186A3B;
  width: 50px;
  height: 50px;
  margin: 10px;
  border-radius: 50px;
}

.color8 {
  background-color: #2F876E;
  width: 50px;
  height: 50px;
  margin: 10px;
  border-radius: 50px;
}

.color9 {
  background-color: #512E5F;
  width: 50px;
  height: 50px;
  margin: 10px;
  border-radius: 50px;
}

.com-header h2 {
  color: var(--font-color);
}

.heder_profile h4 {
  font-family: var(--fontfamily);
}

.Notfound {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.Notfound header {
  background-color: black;
  display: flex;
  align-items: center;
  height: 10vh;
  color: white;
  padding-left: 20px;
}

.Notfound ul {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 90vh;
  justify-content: center;
  align-items: center;
}

.Notfound h1 {
  font-size: 100px;
  color: #ebb569;
}

.Notfound img {
  width: 300px;
  height: 300px;
}

.Notfound button {
  height: 35px;
  width: 150px;
  margin-top: 20px;
  border-radius: 40px;
}

.Notfound li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heder_business_dropdown {
  display: flex;
  align-items: center;
  width: 300px;
  height: 50px;
  margin-left: 870px;
  position: absolute;
}

.heder_business_dropdown .input-label {
  font-size: 18px;
  margin-left: 20px;
  position: absolute;
  font-weight: bold;
}

.heder_business_dropdown .business-input {
  width: 300px;
  height: 35px;
  padding-left: 10px;
  border: none;
  border-radius: 20px;
  font-weight: normal;
  font-size: 10px;
  background-color: #a7bbd4;
  cursor: pointer;
}

.button_head {
  margin-left: 400px;
  margin-top: 10px;
  width: 29%;
  display: flex;
  justify-content: space-between;
}

.select_button {
  position: relative;
  right: 60px;
  top: 10px;
}

.select_bus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-top: 20px;
}
.date_time_style{
  width: 20%;
  padding: 5px;
  font-size: 15px;
  display: flex;
  position: relative;
  left: 100px;
}

.date_time_style p{
  display: flex;
}

.grid-container {
  display: grid;
  grid-template-columns: auto 1fr;  
  align-items: center;
}

.search_header {
  position: relative;
  width: 80%;
  margin-top: 0;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.search_box {
  position: relative;
  width: 40%;
  height: 55px;
  border-radius: 4px;
  margin: 20px auto 10px; 
  background-color: rgb(251, 247, 247);
}


/* .table_container {
  position: fixed; 
  left:920px;
  padding: 5px;
  margin-top: -20px;
  width: 22vw; 
  height: auto;
  border-radius: 5px;
  background-color:hsl(0, 73%, 99%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  z-index: 1000;
} */

.table_container {
  position: absolute; 
  top: 71%; 
  left: 0; 
  padding: 5px;
  width: 22vw;
  max-width: 374px; 
  height: auto;
  border-radius: 5px;
  background-color: hsl(0, 73%, 99%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

@media (max-width: 768px) {
  .table_container {
    width: 80vw; 
    left: 0; 
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid #ccc;
  text-align: left;
}

.search_box .MuiOutlinedInput-input::-webkit-input-placeholder {
  font-size: 16px;
  color: black;
}

.search_box .MuiOutlinedInput-input:-moz-placeholder {
  font-size: 16px;
  color: #c0a6a6;
}

.search_box .MuiOutlinedInput-input::-moz-placeholder {
  font-size: 16px;
  color: #301919;
}

.search_box .MuiOutlinedInput-input:-ms-input-placeholder {
  font-size: 16px;
  color: #ccc;
}


.logo_header {
  position: relative;
}

.logo_header img, .logo_header svg {
  width: 35px; 
  height: 30px; 
  object-fit: cover; 
  margin-top:-3px;
}