body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::placeholder {
  text-align: center;
  font-size: 55px ;
  color: 'red';
}


::-webkit-input-placeholder {
  text-align: center;
  font-size: 55px ;
  color: 'red';
}

:-moz-placeholder { /* Firefox 18- */
  text-align: center; 
  font-size: 55px ;
  color: 'red'; 
}

::-moz-placeholder {  /* Firefox 19+ */
  text-align: center;
  font-size: 55px ;
  color: 'red';  
}

:-ms-input-placeholder {  
  text-align: left; 
  font-size: 55px ;
  color: 'red';
}