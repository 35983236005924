.dashboard-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: rgb(176, 173, 173);
}

.dashboard-main {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
}

.dashboard-full-card {
    grid-column: span 3;
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 8px;
}

.chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.year-controls {
    display: flex;
    align-items: center;
}

.dashboard-button {
    background: none;
    border: none;
    color: #171616;
    cursor: pointer;
    margin: 0 10px;
    font-size: 16px;
    transition: color 0.3s;
}

.dashboard-button:hover {
    color: #ff6b6b;
}

.year-display {
    font-size: 18px;
    margin: 0 10px;
}

.periodic-box {
    /* background-color: #f7f7f7;
    padding: 20px;
    border: 1px solid #ddd;
    margin-top: 20px; */
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 8px;
}

.periodic-reports {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: #0b0b0b;
}

.date-selection {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.date-selection label {
    margin-right: 10px;
}

.date-input {
    margin-right: 20px;
    padding: 5px;
    border: none;
    border-radius: 4px;
    background: #333;
    color: #fff;
}

/* .dashboard-controls {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    margin-bottom: 20px; 
  }
  .dashboard-button {
    background-color: #007bff;
    color: #fff; 
    border: none;
    border-radius: 4px;
    padding: 10px 15px; 
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
  }
  
  .dashboard-button:hover {
    background-color: #0056b3;
  }
  
  .dashboard-toggle {
    display: flex;
    align-items: center; 
    margin: 0 10px;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px; 
    margin-left: 8px;
  }
  
  .switch input {
    opacity: 0;
    width: 0; 
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px; 
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 12px; 
    width: 12px; 
    left: 4px;
    bottom: 4px; 
    background-color: white; 
    border-radius: 50%; 
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3; 
  }
  
  input:checked + .slider:before {
    transform: translateX(14px);
  } */

.dashboard-total-sales {
    background-color: white;
    color: rgb(15, 14, 14);
    padding: 20px;
    border-radius: 8px;
}

.dashboard-product-list {
    background-color: white;
    color: rgb(20, 20, 20);
    padding: 20px;
    border-radius: 8px;
}

.dashboard-Hourly-sales-card {
    background-color: white;
    color: rgb(35, 33, 33);
    padding: 20px;
    border-radius: 8px;
}

.dashboard-top-categories-card {
    background-color: white;
    color: rgb(10, 10, 10);
    padding: 20px;
    border-radius: 8px;
}

.dashboard-top-customer-card {
    background-color: white;
    color: rgb(20, 20, 20);
    padding: 20px;
    border-radius: 8px;
}