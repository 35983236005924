.carwash_bodyalignment {
  width: 100vw;
  height: 100vh;
}

.carwash_bodyalignment_payment{
  width: 100vw;
  height: 90.2vh;
}
.image_carwash {
  background-image: url("../../../assets/Images/car-wash-background.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
}

.flow_alignment {
  width: 58%;
}

.price_alignment {
  width: 40%;
  margin-right: 10px;
}

.order_details_wash {
  background-color: aqua;
}

.consider_price {
  height: 15vh;
}

.tab-container {
  display: flex;
  width: 90%;
  color: #007bff;
  height: 55px;
  flex-direction: row; 
  justify-content: flex-start; 
  align-items: flex-end; 
  border-radius: 10px;
  padding: 0; 
  margin: 20px 0; 
  white-space: nowrap; 
  border-bottom: 2px solid #ddd; 
  background-color:#e1e1e1;
}

.tab-item {
  flex: none; 
  padding: 10px 20px 10px ; 
  font-size: 16px; 
  cursor: pointer; 
  color: blue; 
  border-radius: 10px 10px 0 0; 
  position: relative;
  bottom: -2px; 
}

.tab-item.active-tab {
  background-color: #ffffff;
  color: #007bff; 
  font-weight: bold; 
  border: 1px solid transparent; 
  border-bottom: none; 
  z-index: 1; 
  border-radius: 10px 10px 0 0; 
  margin: 0px 10px 0px 10px;
}

.products {
  display: flex;
  margin-bottom: 200px;
  flex-wrap: wrap; 
  gap: 20px; 
  justify-content: space-evenly; 
  /* padding: 20px; */
}

.cointainer {
  height: 90vh;
  width: 50vw;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 30px auto;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto; 
  display: flex;
  flex-wrap: wrap;
  /* gap: 20px; */
  justify-content: center; 
  align-items: flex-start; 
}

.card {
  width: calc(45% - 10px);
  /* background-color: red; */
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}


.amount {
  font-size: 1rem;
  font-weight: 500;
  color: #e6383e;
}


@media (max-height: 768px) {
  .cointainer {
    height: 80.5vh; 
  }
}

@media (max-height: 480px) {
  .cointainer {
    height: 80vh; 
  }
}

.search-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.search-icon {
  font-size: 20px;
  color: #333;
  margin-top: 5px;
  /* position: fixed; */
}

.input-search {
  flex-grow: 1;
  margin-top: 5px;
  /* position: fixed; */
  margin-left: 15px;
  width: 30vw;
  /* background-color: red; */
  height: 40px;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 0 10px;
  font-size: 16px;
}

.collecting-data {
  display: flex;
  flex-direction: column;
  gap: 20px;

  overflow-y: auto;
}

.collecting-data p {
  width: 25vw;
  background-color: #f0f8ff;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-left: 70px;

  align-items: center;
  font-size: 16px;

  transition: background-color 0.3s ease;
}

.collecting-data p:hover {
  background-color: #e6f7ff;
}

.collecting-price {
  font-weight: bold;
  color: #007bff;
}

.price_structure {
  display: flex;
  background-color: rgba(255, 255, 255, 0.95);
  /* background-color: green; */
  width: 50vw;
  max-width: 450px;
  height: auto;
  margin: 30px 0 0 10px;
  padding: 15px 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

@media (min-width: 768px) {
.price_structure{
  width: 39vw;
}
}

.price_brify {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.total_price {
  color: #e63946;
  font-weight: bold;
  font-size: 35px;
}

.placement-order {
  background-color: rgba(255, 255, 255, 0.95);
  height: 150px;
  width: 50vw;
  border-radius: 15px;
  max-width: 450px;
  margin: 0 0 0 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  text-align: center;
  font-family: 'Arial', sans-serif;
  height: 470px;
  margin-top: 20px;
  overflow: auto;
}

@media (min-width: 768px) {
  .placement-order{
    width: 39vw;
  }
}
.card {
  width: auto;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}


.product-name {
  font-size: 16px; 
  font-weight: normal !important; 
  margin-left: 20px; 
}


.delete-icon:hover {
  color: darkred; 
}
.food-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    max-height: 70vh; 
    overflow-y: auto; 
    padding: 10px;
    background-color: #f9f9f9; 
    /* background-color: #e63946; */
    border: 1px solid #ccc; 
    border-radius: 8px;
}


.food-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 15px;
  cursor: pointer;
  background-color: #f9f9f9;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.food-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.food-item img {
  max-width: 120px;
  max-height: 100px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.food-item ul {
  width: 100%;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.food-item ul li {
  font-weight: bold;
  color: #333;
}

.order_quentity ul li {
  display: flex;
  justify-content: end;
  font-size: 18px;
}

.input_image {
  background-color: #007bff;
  display: flex;
}

.order_na_quen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.order_name {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.overbody-cash-payment {
  width: 100vw;
  height: auto;
  /* height: 90.2vh; */
  /* background-color: green; */
}

.payment-bar {
  width: 60%;
  height: 50px;
  background-color: #f9f9f9;
  border-radius: 15px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .payment-bar {
    width: 90%;
  }
}

.pay-process-flow-check {
  background-color: rgba(255, 255, 255, 0.9);
  margin: 20px auto;
  width: 60vw;
  height: auto; 
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column; 
  gap: 20px; 
  align-items: center; 
}

.discount-amount {
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: auto; 
  max-width: 150px;
  outline: none;
}

@media (max-width: 768px) {
  .pay-process-flow-check {
    width: 90%; 
    padding: 15px;
  }
}


@media (min-width: 768px) {
  .pay-summary ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px ;
    flex-wrap: wrap;
    padding-left: 130px;
    width: 80%;
  }
}

.pay-summary .label {
  font-weight: bold;
  color: #721c24;
}

.pay-summary .value {
  color: #495057;
}


@media (max-width: 768px) {
  .pay-button {
    width: 90%; 
  }
}

.priority-icon-wrapper {
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin: 10px auto; 
}

.priority-icon {
  position: relative;
  bottom: 40px; 
  font-size: 70px; 
  border: 1px solid black; 
  width: 65px; 
  height: 75px; 
  border-radius: 50%; 
  background-color: white; 
  display: flex; 
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}
 .pay-process-flow-check-message h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.icon-options {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.icon-wrapper {
  text-align: center;
}

.icon-wrapper p {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.whattsapp-icon, .print-icon {
  height: 100px;
  width: 100px;
  color: #25D366;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.whattsapp-icon:hover, .print-icon:hover {
  transform: scale(1.1);
  color: #128C7E;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.action-buttons .MuiButton-root {
  padding: 10px 20px;
  font-size: 14px;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.overbody-cash {
  padding: 20px;
  background-color: #f9f9f9;
}

.payment-bar {
  width: 60vw;
}

.full-file-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  height: auto;
  
}

.carorder-datails-flow {
  flex: 1 1 45%; 
  display: flex;
  flex-direction: column;
  background: #ffffff; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
  font-family: Arial, sans-serif; 
}

.carorder-datails-flow ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.carorder-datails-flow ul li {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding: 10px 0;
  border-bottom: 1px solid #eee; 
}

.carorder-datails-flow ul li:last-child {
  border-bottom: none; 
}

.carorder-datails-flow .label {
  font-weight: bold;
  color: #333; 
  flex: 0 0 40%; 
}

.carorder-datails-flow .value {
  color: #555; 
  flex: 0 0 60%; 
  text-align: right; 
}

.carorder-datails-flow input.value {
  width: 100px; 
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: right;
  color: #333;
}

.carorder-datails-flow input.value:focus {
  border-color: #007bff;
  outline: none;
}

@media (max-width: 600px) {
  .carorder-datails-flow ul li {
    flex-direction: column; 
    align-items: flex-start;
  }

  .carorder-datails-flow .label,
  .carorder-datails-flow .value {
    flex: none;
    text-align: left;
    width: 100%; 
  }

  .carorder-datails-flow input.value {
    width: 100%; 
  }
}

@media (max-width: 768px) {
  .carorder-datails-flow, .service-plan-details {
    flex: 1 1 100%; 
  }

  .service-item {
    flex: 1 1 100%; 
  }
}
.service-plan-options th,
.service-plan-options td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.service-plan-options th {
  background-color: #f4f4f4;
}

.pay-summary {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-top: 10px;
}

.pay-summary ul {
  list-style: none;
  padding: 0;
}

.pay-summary ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.pay-summary .label {
  font-weight: bold;
  color: #333;
}

.pay-summary .value {
  color: #555;
}

.header-alignment {
  background-color: #dc3545;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
}

.pay-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  /* .payment-section {
    padding: 15px;
  } */

  .full-file-content {
    flex-direction: column;
  }

  .carorder-datails-flow {
    width: 100%;
   
  }

  .service-plan-options {
    flex-direction: column;
    gap: 15px;
  }

  .pay-button {
    width: 100%;
  }
}

.service-plan-details {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-plan-details h3,
.service-plan-details h4 {
  color: #333;
}

table {
  width: 100%;
  border-collapse: collapse;
  /* margin-top: 15px; */
  /* margin-bottom: 15px; */
  font-size: 14px;
  color: #555;
}

table th, 
table td {
  text-align: left;
  padding: 10px;
}


thead th {
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
  font-weight: bold;
  color: #444;
  border-bottom: 2px solid #ddd;
  padding: 10px;
  z-index: 1; 
}

.bodyali{
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
  font-weight: bold;
  color: #444;
  border-bottom: 2px solid #ddd;
  padding: 10px;
  z-index: 1; 
}
table td {
  border-bottom: 1px solid #eee;
}

table tr:hover {
  background-color: #f9f9f9;
}


table tr:last-child td {
  border-bottom: none;
}

.button-structure {
  background-color: #f8f9fa; 
  width: 25vw; 
  padding: 10px; 
  border-radius: 8px; 
  display: flex;
  justify-content: space-between;
  align-items: center; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.button-structure button {
  padding: 10px 15px; 
  border: none; 
  border-radius: 5px; 
  font-size: 14px; 
  font-weight: bold; 
  cursor: pointer; 
  transition: background-color 0.3s ease, transform 0.2s ease; 
}

.button-structure button:hover {
  transform: scale(1.05); 
}

.button-structure button:active {
  transform: scale(0.95); 
}

.button-structure .pay-button {
  background-color: #007bff; 
  color: white; 
}

.button-structure .pay-button:hover {
  background-color: #0056b3; 
}

.button-structure button:first-child {
  background-color: #dc3545; 
  color: white; 
}

.button-structure button:first-child:hover {
  background-color: #a71d2a; 
}
.structure-alignment {
  background-color: rgba(255, 255, 255, 0.9);
  height: 350px; 
  width: 100%;
  max-width: 600px; 
  margin: 140px auto;
  padding: 20px; 
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
  text-align: center; 
}

.structure-alignment h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.structure-alignment p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.structure-alignment div {
  display: flex;
  justify-content: center;
  gap: 20px; 
}

.structure-alignment img {
  width: 140px;
  height: auto; 
  cursor: pointer; 
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.structure-alignment img:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
}
.structure-alignment {
  background-color: rgba(255, 255, 255, 0.9);
  max-width: 600px; 
  width: 90%; 
  margin: 140px auto; 
  padding: 30px; 
  border-radius: 12px; 
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15); 
  text-align: center;
}

.structure-alignment h1 {
  font-size: 26px; 
  color: #333;
  margin-bottom: 15px; 
}

.structure-alignment p {
  font-size: 16px;
  color: #666;
  margin-bottom: 25px;
}

.structure-alignment div {
  display: flex;
  justify-content: space-evenly; 
  align-items: center; 
  flex-wrap: wrap; 
  gap: 20px; 
}

.payment-option {
  display: flex;
  flex-direction: column; 
  align-items: center;
  gap: 8px; 
}

.payment-option img {
  width: 140px;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.payment-option img:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.payment-option span {
  font-size: 14px;
  color: #555;
  font-weight: bold;
  text-align: center;
}

.input_alignment {
  /* margin-top: 20px; */
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  /* background-color: red; */
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.selected-products-box {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  height: 350px;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.selected-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  width: 100%;;
}

.selected-item:last-child {
  border-bottom: none;
}

.product-name {
  font-size: 16px;
  font-weight: bold;
}

.product-amount {
  font-size: 16px;
  color: #3b5ece;
  
}

.delete-icon {
  color: red;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.delete-icon:hover {
  transform: scale(1.2);
}

.empty-message {
  color: #999;
  font-style: italic;
  text-align: center;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}

@media print {
  body * {
    visibility: hidden;
  }
  .print-section, .print-section * {
    visibility: visible;
  }
  .print-section {
    position: absolute;
    top: 0;
    left: 0;
  }
}


.Car-button{
  background-color: green;
  height: 40px;
  width: 140px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  outline: none;
}
.Car-button-popup{
  background-color: green;
  height: 40px;
  width: 140px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  outline: none;
}
.next-button{
  background-color: #3b5ece; 
  height: 40px;
  width: 140px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  outline: none;
}
.next-button-popup{
  background-color: #c52641; 
  height: 40px;
  width: 140px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  outline: none;
}

.cancel-button{
  display: flex;
    justify-content: space-evenly;
    margin-top: 15px
}

.car-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
}

.car-card {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  width: 400px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 25px;
}
.car-card:hover {
  /* transform: translateY(-5px); */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border: 2px solid rgb(55, 54, 54);
}

.disabled-card {
  pointer-events: none;
  opacity: 0.5;
}

.product-list {
  margin-top: 8px;
}

.product-item {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  border-bottom: 1px solid #eaeaea;
}

.product-item:last-child {
  border-bottom: none;
}
.add-car-actions{
  margin-top: 15px;
  display: flex;
  justify-content: space-evenly;
}

.input_alignment {
  height: auto;
  max-height: 355px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  justify-content: flex-start;
  width: 100%;
}

.input_alignment ul {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  margin: 0;
}

.input_alignment li {
  background-color: #d9eeee;
  padding: 10px;
  border-radius: 8px;
  width: calc(100% - 20px);
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order_na_quen {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.order_na_quen img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.order_name {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.order_notes {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.input_alignment input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.order_na_quen .delete_icon {
  position: relative;
  left: 20px;
  bottom: 5px;
}

@media screen and (max-width: 768px) {
  .input_alignment {
    padding: 10px;
  }

  .input_alignment li {
    width: 100%;
    max-width: 100%;
  }

  .order_name {
    flex-direction: column;
    align-items: flex-start;
  }

  .order_na_quen img {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 480px) {
  .order_na_quen {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .order_name {
    width: 100%;
    flex-direction: column;
  }

  .order_notes {
    flex-direction: column;
  }

  .input_alignment input {
    font-size: 14px;
    padding: 8px;
  }
}
