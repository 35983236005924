.Master_header {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #1b7bdb;
  border-bottom: 1px solid #ccc;
}

.master_table_container {
  margin: 20px;
  max-width: 97%;
}

.signout_btn {
  padding: 10px 10px;
  background-color: #d7262b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.signout_btn:hover {
  background-color: #ec5555;
}
