.overbody-cash-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-color: #128C7E; */
    background-color: #f5f5f5;
}
.inside_image {
    /* background-image: url("../../../assets/10.webp"); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.overbody-cash-payment-print{
    width: 100vw;
    height: 90.2vh;
    /* background-color: green; */
  }

.inside_image-message {
    margin-bottom: 100px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    max-width: 600px;
    width: 100%;
    height: 430px;
    margin-top: 100px;
}

.pay-process-flow-check-message h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.description {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.icon-options {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}

.icon-wrapper {
    text-align: center;
}

.icon-wrapper p {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
}

.whattsapp-icon, .print-icon {
    height: 100px;
    width: 100px;
    color: #25D366;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.whattsapp-icon:hover, .print-icon:hover {
    transform: scale(1.1);
    color: #128C7E;
}

.action-buttons {
    display: flex;
    justify-content: space-around;
    gap: 15px;
}

.action-buttons .MuiButton-root {
    padding: 10px 20px;
    font-size: 14px;
}
.Print_popup_carwash{
 position: fixed;
  top: 0%;
  left: 0%;
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  height: auto;
  border-radius: 3px;
  background-color: aliceblue;
  font-size: 20px;
  color: black;
}
.car_structure{
    border: 1px solid black;
    border-radius: 7px;
    margin-top: 5px;
    padding: 5px;
}