.home-container {
    background-image: url("../../../assets/Images/car-wash-background.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    overflow: hidden;
  }

.details-container {
    flex-grow: 1;
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.details-title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.details-paper {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.details-paper .MuiTypography-body1 {
    font-size: 1rem;
    margin: 10px 0;
    color: #555;
}

.back-button {
    background-color: #1976d2;
    color: white;
    text-transform: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 20px;
    font-weight: bold;
    &:hover {
      background-color: #1565c0;
    }
}

  @media (max-width: 768px) {
    .details-container {
      padding: 15px;
    }

    .details-paper {
      padding: 15px;
    }

    .details-title {
      font-size: 1.5rem;
    }

    .back-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }

.order-table {
  width: 100%;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}




.table-container-wrapper {
  margin: 40px;
  width: 90%;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.table-container {
  width: 90%;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.table-header .MuiTableCell-root {
  color: white;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}

.table-row {
  &:nth-of-type(odd) {
    background-color: #f9f9f9;
  }
}

.table-row .MuiTableCell-root {
  font-size: 0.9rem;
  text-align: center;
  color: #555;
}

.view-details-button {
  background-color: #1976d2;
  color: white;
  font-weight: bold;
  text-transform: none;
  &:hover {
    background-color: #1565c0;
  }
}

@media (max-width: 768px) {
  .table-container {
    width: 100%;
    margin: 0 10px;
  }

  .table-header .MuiTableCell-root,
  .table-row .MuiTableCell-root {
    font-size: 0.8rem;
    padding: 8px;
  }

  .view-details-button {
    font-size: 0.8rem;
    padding: 5px 10px;
  }
}

.table-header {
  font-weight: bold;
  background-color: #f5f5f5;
  z-index: 0;
}
.cash {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height:auto !important;
}

.cash-header {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}
.order-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.order-id {
  flex: 1;
  text-align: left;
  font-weight: bold;
  margin-left: 8px;
}
.order-id-value {
  font-weight: normal; 
}

.order-time-date {
  text-align: right;
  flex: 1;
}

.order-detail {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
  align-items: center;
}

.order-detail .label {
  font-weight: bold;
  text-align: left;
  flex: 2;
  margin-left: 70px;
  max-width: 20%;
}

.order-detail .colon {
  margin: 0 5px;
  font-weight: bold;
}

.order-detail .value {
  text-align: left;
  flex: 2;
  padding-left: 10px;
}

.cash-details {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-sizing: border-box;
  margin-top: -30px;
}

.cash-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cash-detail .label {
  font-weight: bold;
  text-align: left;
  flex: 1;
}

.cash-detail .colon {
  margin: 0 10px 10px 20px;
  font-weight: bold;
}

.cash-detail .value {
  text-align: left;
  flex: 2;
}
.received-input {
  width: 100px;
  padding: 8px;
  margin-left: -260px;
  text-align: left;
  margin-right: 160px;
}

.cash-actions {
  display: flex;
  justify-content: center;
  gap: 100px;
}
.cash-actions .Cancel-btn {
  background-color: #f44336; 
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 5px;
  text-transform: none;
  outline: none;
}
.cash-actions .Cancel-btn:hover,
.cash-actions .Cancel-btn:active,
.cash-actions .Cancel-btn:focus {
  background-color: #f44336;
  outline: none; 
}

.cash-actions .Confirm-btn {
  background-color: #4CAF50;  
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 5px;
  text-transform: none;
}
.cash-actions .Confirm-btn:hover,
.cash-actions .Confirm-btn:active,
.cash-actions .Confirm-btn:focus {
  background-color: #4CAF50;  
  outline: none;
  box-shadow: none;
}
.buy_on_credit {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: auto;
}

.buy_on_credit-header {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}
.order-infos {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.order-id {
  flex: 1;
  text-align: left;
  font-weight: bold;
  margin-left: 8px;
}
.order-id-value {
  font-weight: normal; 
}

.order-time-dates {
  text-align: right;
  flex: 1;
}

.order-detail {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
  align-items: center;
}

.order-detail .label {
  font-weight: bold;
  text-align: left;
  flex: 2;
  margin-left: 70px;
  max-width: 20%;
}

.order-detail .colon {
  margin: 0 5px;
  font-weight: bold;
}

.order-detail .value {
  text-align: left;
  flex: 2;
  padding-left: 10px;
}

.buy_on_credit-details {
  border: 1px solid #ccc; 
  padding: 16px; 
  border-radius: 8px; 
  background-color: #f9f9f9; 
  box-sizing: border-box; 
  margin-top: -30px;
}

.buy_on_credit-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.buy_on_credit-detail .label {
  font-weight: bold;
  text-align: left;
  flex: 1;
}

.buy_on_credit-detail .colon {
  margin: 0 10px 10px 20px;
  font-weight: bold;
}

.buy_on_credit-detail .value {
  text-align: left;
  flex: 2;
}

.buy_on_credit-actions {
  display: flex;
  justify-content: center;
  gap: 100px;
  margin-top: 10px;
}

.buy_on_credit-actions  .Cancel_btn {
  background-color: #f44336; 
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 5px;
  text-transform: none;
  outline: none;
}
.buy_on_credit-actions  .Cancel_btn:hover,
.buy_on_credit-actions .Cancel_btn:active,
.buy_on_credit-actions  .Cancel_btn:focus {
  background-color: #f44336;
  outline: none; 
}

.buy_on_credit-actions .Confirm_btn {
  background-color: #4CAF50;  
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 5px;
  text-transform: none;
}
.buy_on_credit-actions .Confirm_btn:hover,
.buy_on_credit-actions  .Confirm_btn:active,
.buy_on_credit-actions .Confirm_btn:focus {
  background-color: #4CAF50;  
  outline: none;
  box-shadow: none;
}
